import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { closeContactDetailsAlertDialog } from 'app/auth/store/userSlice';
import History from '@history';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Home, Mail, Phone, Web } from '@material-ui/icons';

export default function ContactUserAlert() {
  const [open, setOpen] = React.useState(false);
  const dialog = useSelector(({ auth }) => auth.user.contactDetailsAlertDialog);
  const dispatch = useDispatch();

  const { data } = dialog?.props || { data: {} };

  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    dispatch(closeContactDetailsAlertDialog());
  }

  return (
    <Dialog
      {...dialog?.props}
      onClose={closeComposeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: 'm-24 rounded-8',
      }}
    >
      <DialogTitle id="alert-dialog-title">{data.companyName} Contact Information</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <List>
            {data.phone?.length > 0 && (
              <ListItem>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <ListItemText primary={data.phone} />
              </ListItem>
            )}
            {data.email?.length > 0 && (
              <ListItem>
                <ListItemIcon>
                  <Mail />
                </ListItemIcon>
                <ListItemText primary={data.email} />
              </ListItem>
            )}
            {data.website?.length > 0 && (
              <ListItem>
                <ListItemIcon>
                  <Web />
                </ListItemIcon>
                <ListItemText primary={data.website} />
              </ListItem>
            )}
            {data.address?.length > 0 && (
              <ListItem>
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText primary={data.address} />
              </ListItem>
            )}
          </List>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
