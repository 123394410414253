import { lazy } from 'react';

const IndustriesRoutes = [
  {
    path: '/results/industries/:industryId',
    component: lazy(() => import(/* webpackChunkName: "Industry Landing Page" */ './industry/Industry')),
  },
];

export default IndustriesRoutes;
