import { lazy } from 'react';
import { Redirect } from 'react-router';
import IndustriesRoutes from './search-results/industries/IndustriesRoutes';
import ResultItemRoutes from './search-results/result-items/ResultItemRoutes';

const WebAppPagesConfig = {
  settings: {
    layout: {
      config: {
        scroll: "content",
        navbar: {
          display: true,
          folded: true,
          position: "left",
        },
        toolbar: {
          display: true,
          style: "fixed",
          position: "below",
        },
        footer: {
          display: true,
          style: "fixed",
          position: "below",
        },
        mode: "fullwidth",
      },
    },
  },
  routes: [
    {
      path: "/",
      component: lazy(() => import(/* webpackChunkName: "Landing Page" */ "./LandingPage")),
      exact: true,
    },
    {
      path: "/results",
      component: lazy(() =>
        import(
          /* webpackChunkName: "Search Results" */  "./search-results/ResultsPageLayout"
        )
      ),
      routes: [
        ...ResultItemRoutes,
        ...IndustriesRoutes,
        {
          path: "/results",
          component: () => <Redirect to="/results/industries" />,
        },
      ],
    },
  ],
};

export default WebAppPagesConfig;
