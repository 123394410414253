const settingsConfig = {
  layout: {
    style: "layout2", // layout1 layout2 layout3
    config: {
      mode: {
        type: String,
        default: "fullwidth",
      },
      scroll: {
        type: String,
        default: "content",
      },
      navbar: {
        display: {
          type: Boolean,
          default: true,
        },
      },
      toolbar: {
        display: {
          type: Boolean,
          default: true,
        },
        position: {
          type: String,
          default: "below",
        },
      },
      footer: {
        display: {
          type: Boolean,
          default: true,
        },
        style: {
          type: String,
          default: "fixed",
        },
      },
    }, // checkout default layout configs at app/fuse-layouts for example  app/fuse-layouts/layout1/Layout1Config.js
  },
  customScrollbars: true,
  direction: "ltr", // rtl, ltr
  theme: {
    main: "light12",
    navbar: "light12",
    toolbar: "light12",
    footer: "light12",
  },
};

export default settingsConfig;
