import { combineReducers } from '@reduxjs/toolkit';
import businessTypes from './businessTypesSlice';
import industries from './industriesSlice';
import sectors from './sectorsSlice';
import products from './productsSlice';
import services from './servicesSlice';
import categories from './categoriesSlice';
import jobCategories from './jobCategoriesSlice';
import jobTypes from './jobTypesSlice';
import trades from './tradesSlice';

const reducer = combineReducers({
  businessTypes,
  industries,
  sectors,
  products,
  services,
  categories,
  jobCategories,
  jobTypes,
  trades,
});

export default reducer;
