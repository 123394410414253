import { authRoles } from 'app/auth';
import { lazy } from 'react';

const Error404PageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/404',
      component: lazy(() => import(/* webpackChunkName: "Error 404" */ './Error404Page')),
    },
  ],
};

export default Error404PageConfig;
