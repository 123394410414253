import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getSectors = createAsyncThunk('suggestions/sectors/getSectors', async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/sectors`, { params });
  const data = await response.data;

  return data;
});

export const removeSectors = createAsyncThunk(
  'suggestions/sectors/removeSectors',
  async (sectorIds, { dispatch, getState }) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/sectors/remove-sectors`, {
      sectorIds,
    });

    return sectorIds;
  }
);

const sectorsAdapter = createEntityAdapter({});

export const { selectAll: selectSectors, selectById: selectSectorById } =
  sectorsAdapter.getSelectors((state) => state.suggestions.sectors);

const sectorsSlice = createSlice({
  name: 'suggestions/sectors',
  initialState: sectorsAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setSectorsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getSectors.fulfilled]: sectorsAdapter.setAll,
    [removeSectors.fulfilled]: (state, action) => sectorsAdapter.removeMany(state, action.payload),
  },
});

export const { setSectorsSearchText } = sectorsSlice.actions;

export default sectorsSlice.reducer;
