import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseShortcuts from '@fuse/core/FuseShortcuts';
import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
}));

function NavbarWrapperMobile(props) {
  const classes = useStyles(props);

  return (
    <Hidden lgUp>
      <div className={clsx('w-full shadow-md', classes.root, props.className)}>
        <div
          className={clsx(
            'justify-between items-center w-full h-full container py-10 lg:px-24 z-20 md:p-10'
          )}
        >
          <FuseScrollbars className="flex h-full w-full items-center md:mx-10">
            <FuseShortcuts />
          </FuseScrollbars>
        </div>
      </div>
    </Hidden>
  );
}

export default memo(NavbarWrapperMobile);
