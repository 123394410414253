import UserEditProfileAppConfig from "./user-edit-profile/UserEditProfileAppConfig";
import UserJobPostsAppConfig from "./user-posts/UserJobPostsAppConfig";
import JobPostsAppConfig from "./job-posts/JobPostsAppConfig";

const appsConfigs = [
  JobPostsAppConfig,
  UserJobPostsAppConfig,
  UserEditProfileAppConfig,
];

export default appsConfigs;
