import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getJobCategories = createAsyncThunk(
  'suggestions/jobCategories/getJobCategories',
  async (params) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/job-categories`, {
      params,
    });
    const data = await response.data;

    return data;
  }
);

export const removeJobCategories = createAsyncThunk(
  'suggestions/jobCategories/removeJobCategories',
  async (jobCategoryIds, { dispatch, getState }) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/jobCategories/remove-jobCategories`, {
      jobCategoryIds,
    });

    return jobCategoryIds;
  }
);

const jobCategoriesAdapter = createEntityAdapter({});

export const { selectAll: selectJobCategories, selectById: selectJobCategoryById } =
  jobCategoriesAdapter.getSelectors((state) => state.suggestions.jobCategories);

const jobCategoriesSlice = createSlice({
  name: 'suggestions/jobCategories',
  initialState: jobCategoriesAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setJobCategoriesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getJobCategories.fulfilled]: jobCategoriesAdapter.setAll,
    [removeJobCategories.fulfilled]: (state, action) =>
      jobCategoriesAdapter.removeMany(state, action.payload),
  },
});

export const { setJobCategoriesSearchText } = jobCategoriesSlice.actions;

export default jobCategoriesSlice.reducer;
