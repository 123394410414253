import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getAds = createAsyncThunk("auth/ads/getAds", async (params) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/job-posts`,
    { params }
  );
  const data = await response.data;

  return { data, params };
});

export const addAd = createAsyncThunk(
  "auth/ads/addAd",
  async (ad, { dispatch, getState }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/job-posts`,
      ad
    );
    const data = await response.data;

    dispatch(getAds({ user: ad.user }));

    return data;
  }
);

export const updateAd = createAsyncThunk(
  "auth/ads/updateAd",
  async (ad, { dispatch, getState }) => {
    const response = await axios.post("/api/ads-app/update-ad", { ad });
    const data = await response.data;

    return data;
  }
);

export const removeAd = createAsyncThunk("auth/ads/removeAd", async (adId) => {
  await axios.delete(`${process.env.REACT_APP_API_URL}/v1/job-posts/${adId}`);

  return adId;
});

export const removeAds = createAsyncThunk(
  "auth/ads/removeAds",
  async (ids, { dispatch, getState }) => {
    await axios.post("/api/ads-app/remove-ads", { ids });

    return ids;
  }
);

export const toggleStarredAd = createAsyncThunk(
  "auth/ads/toggleStarredAd",
  async (adId, { dispatch, getState }) => {
    const response = await axios.post("/api/ads-app/toggle-starred-ad", {
      adId,
    });
    const data = await response.data;

    dispatch(getAds());

    return data;
  }
);

export const toggleStarredAds = createAsyncThunk(
  "auth/ads/toggleStarredAds",
  async (adIds, { dispatch, getState }) => {
    const response = await axios.post("/api/ads-app/toggle-starred-ads", {
      adIds,
    });
    const data = await response.data;

    dispatch(getAds());

    return data;
  }
);

export const setAdsStarred = createAsyncThunk(
  "auth/ads/setAdsStarred",
  async (adIds, { dispatch, getState }) => {
    const response = await axios.post("/api/ads-app/set-ads-starred", {
      adIds,
    });
    const data = await response.data;

    dispatch(getAds());

    return data;
  }
);

export const setAdsUnstarred = createAsyncThunk(
  "auth/ads/setAdsUnstarred",
  async (adIds, { dispatch, getState }) => {
    const response = await axios.post("/api/ads-app/set-ads-unstarred", {
      adIds,
    });
    const data = await response.data;

    dispatch(getAds());

    return data;
  }
);

const adsAdapter = createEntityAdapter({});

export const { selectAll: selectAds, selectById: selectAdsById } =
  adsAdapter.getSelectors((state) => state.auth.ads);

const adsSlice = createSlice({
  name: "auth/ads",
  initialState: adsAdapter.getInitialState({
    searchText: "",
    count: 0,
    routeParams: {},
    adDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setAdsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    setAdsCount: {
      reducer: (state, action) => {
        state.count = action.payload;
      },
    },
    openNewAdDialog: (state, action) => {
      state.adDialog = {
        type: "new",
        props: {
          open: true,
        },
        to: action.payload,
        data: null,
      };
    },
    closeNewAdDialog: (state, action) => {
      state.adDialog = {
        type: "new",
        props: {
          open: false,
        },
        to: null,
        data: null,
      };
    },
    openEditAdDialog: (state, action) => {
      state.adDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditAdDialog: (state, action) => {
      state.adDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [updateAd.fulfilled]: adsAdapter.upsertOne,
    [addAd.fulfilled]: adsAdapter.addOne,
    [removeAds.fulfilled]: (state, action) =>
      adsAdapter.removeMany(state, action.payload),
    [removeAd.fulfilled]: (state, action) =>
      adsAdapter.removeOne(state, action.payload),
    [getAds.fulfilled]: (state, action) => {
      const { data, routeParams } = action.payload;
      adsAdapter.setAll(state, data);
      state.routeParams = routeParams;
      state.searchText = "";
    },
  },
});

export const {
  setAdsSearchText,
  openNewAdDialog,
  closeNewAdDialog,
  openEditAdDialog,
  closeEditAdDialog,
  setAdsCount,
} = adsSlice.actions;

export default adsSlice.reducer;
