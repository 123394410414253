import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from 'app/auth/store/userSlice';

function UserMenu(props) {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);

   const country = useSelector(({ i18n }) => i18n.language);

  const [userMenu, setUserMenu] = useState(null);
  const [profile, setProfile] = useState('#');

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  useEffect(() => {
    setProfile(`/profile?iso2=${country}`);

    return () => {};
  }, [user]);

  return (
    <>
      <Button
        className="min-w-40 px-0 md:px-10 md:py-2"
        onClick={userMenuClick}
        variant="outlined"
        color="default"
      >
        <div className="hidden md:flex flex-col mx-4 items-end min-w-max">
          <Typography
            component="span"
            className="font-semibold flex"
            align="left"
          >
            {user.name || "Hello User"} {user.lastName}
          </Typography>
          <Typography
            className="text-11 font-medium capitalize"
            color="textSecondary"
            align="left"
          >
            {!user.role || (Array.isArray(user.role) && user.role.length === 0)
              ? "Login or Sign Up"
              : "Account & Profile"}
          </Typography>
        </div>

        {user.picturePath?.length > 0 && (
          <Avatar
            style={{ width: 37, height: 36 }}
            alt="user photo"
            src={
              user.picturePath.startsWith("avatars/")
                ? `${process.env.REACT_APP_FILE_STORAGE_URL}/${user.picturePath}`
                : user.picturePath
            }
          />
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem
              component={Link}
              to={`/login?redirect=${window.location.pathname}&iso2=${country}`}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <Icon>lock</Icon>
              </ListItemIcon>
              <ListItemText primary="Login" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/register?redirect=${window.location.pathname}&iso2=${country}`}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <Icon>person_add</Icon>
              </ListItemIcon>
              <ListItemText primary="Register" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              component={Link}
              to={profile}
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <Icon>account_circle</Icon>
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(logoutUser());
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
