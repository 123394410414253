import { authRoles } from 'app/auth';
import i18next from 'i18next';
import { lazy } from 'react';
import ar from './i18n/ar';
import en from './i18n/en';
import tr from './i18n/tr';

i18next.addResourceBundle('en', 'mailApp', en);
i18next.addResourceBundle('tr', 'mailApp', tr);
i18next.addResourceBundle('ar', 'mailApp', ar);

const JobPostsAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: [
        '/job-vacancies/posts/country/:countryName/:countryId/filter/:filter',
        '/job-vacancies/posts/filter/:filter/:filterName/:filterId',
        '/job-vacancies/posts/filter/:filter',
        '/job-vacancies/posts/:postId',
        '/job-vacancies/posts',
      ],
      component: lazy(() => import(/* webpackChunkName: "Job Posts" */ './MailApp')),
    },
  ],
};

export default JobPostsAppConfig;
