import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getBusinessTypes = createAsyncThunk(
  'auth/businessTypes/getBusinessTypes',
  async (params, { dispatch, getState }) => {
    dispatch(resetBusinessTypes());
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/business-types`, {
      params,
    });
    const data = await response.data;

    return data;
  }
);

export const removeBusinessTypes = createAsyncThunk(
  'auth/businessTypes/removeBusinessTypes',
  async (industryIds, { dispatch, getState }) => {
    await axios.post('/api/businessTypes/remove-businessTypes', { industryIds });

    return industryIds;
  }
);

const businessTypesAdapter = createEntityAdapter({});

export const { selectAll: selectBusinessTypes, selectById: selectIndustryById } =
  businessTypesAdapter.getSelectors((state) => state.auth.businessTypes);

const businessTypesSlice = createSlice({
  name: 'auth/businessTypes',
  initialState: businessTypesAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setBusinessTypesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    resetBusinessTypes: {
      reducer: (state, action) => {
        state.ids = [];
        state.entities = [];
      },
    },
  },
  extraReducers: {
    [getBusinessTypes.fulfilled]: businessTypesAdapter.setAll,
    [removeBusinessTypes.fulfilled]: (state, action) =>
      businessTypesAdapter.removeMany(state, action.payload),
  },
});

export const { setBusinessTypesSearchText, resetBusinessTypes } = businessTypesSlice.actions;

export default businessTypesSlice.reducer;
