import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getJobTypes = createAsyncThunk('suggestions/jobTypes/getJobTypes', async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/job-types`, { params });
  const data = await response.data;

  return data;
});

export const removeJobTypes = createAsyncThunk(
  'suggestions/jobTypes/removeJobTypes',
  async (jobTypeIds, { dispatch, getState }) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/jobTypes/remove-jobTypes`, {
      jobTypeIds,
    });

    return jobTypeIds;
  }
);

const jobTypesAdapter = createEntityAdapter({});

export const { selectAll: selectJobTypes, selectById: selectJobTypeById } =
  jobTypesAdapter.getSelectors((state) => state.suggestions.jobTypes);

const jobTypesSlice = createSlice({
  name: 'suggestions/jobTypes',
  initialState: jobTypesAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setJobTypesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getJobTypes.fulfilled]: jobTypesAdapter.setAll,
    [removeJobTypes.fulfilled]: (state, action) => jobTypesAdapter.removeMany(state, action.payload),
  },
});

export const { setJobTypesSearchText } = jobTypesSlice.actions;

export default jobTypesSlice.reducer;
