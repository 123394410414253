import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeLanguage } from "app/store/i18nSlice";
import useGeoLocation from "react-ipgeolocation";

const GetCoordinates = () => {
  const dispatch = useDispatch();
   const locationData = useGeoLocation();

  const [iso2, setIso2] = useState("");

  /**
   * PARAMS CHANGES & INITIALISATION
   * */
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const iso2Country = queryParams.get("iso2");

    if (iso2Country) setIso2(iso2Country);
    else setIso2("");
  }, [location.search]);

  useEffect(() => {
    if (locationData.country && iso2.length === 0) {
      dispatch(changeLanguage(locationData.country));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);

  return null;
};

export default GetCoordinates;
