import FuseDialog from '@fuse/core/FuseDialog';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from 'app/AppContext';
import { authRoles } from 'app/auth';
import ReportDialog from 'app/fuse-layouts/shared-components/ReportDialog';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { Detector } from 'react-detect-offline';
import ChatAlert from '../shared-components/ChatAlert';
import ContactUserAlert from '../shared-components/ContactUserAlert';
import LoginAlert from '../shared-components/LoginAlert';
import SearchAutocompleteMenu from '../shared-components/searchSuggestions/SearchAutocompleteMenu';
import ShareAlert from '../shared-components/ShareAlert';
import GetCoordinates from '../shared-components/userLoacation/GetCoordinates';
import DescriptionAlerts from './components/DescriptionAlerts';
import FooterLayout2 from './components/FooterLayout2';
import LeftSideLayout2 from './components/LeftSideLayout2';
import NavbarWrapperLayout2 from './components/NavbarWrapperLayout2';
import NavbarWrapperMobile from './components/NavbarWrapperMobile';
import RightSideLayout2 from './components/RightSideLayout2';
import ToolbarLayout2 from './components/ToolbarLayout2';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.boxed': {
      clipPath: 'inset(0)',
      maxWidth: (props) => `${props.config.containerWidth}px`,
      margin: '0 auto',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    },
    '&.container': {
      '& .container': {
        maxWidth: (props) => `${props.config.containerWidth}px`,
        width: '100%',
        margin: '0 auto',
      },
    },
  },
  content: {
    // height: "50vh",
    // overflowY: "scroll",
    // "&::-webkit-scrollbar": {
    //   width: "0px",
    //   color: '#FF0000'
    // },
  },
}));

function Layout2(props) {
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const classes = useStyles({ ...props, config });
  const user = useSelector(({ auth }) => auth.user);
  const searchText = useSelector(({ auth }) => auth.user.searchText);

  return (
    <AppContext.Consumer>
      {({ routes }) => (
        <div id="fuse-layout" className={clsx(classes.root, config.mode, 'w-full flex flex')}>
          <Detector
            render={({ online }) => {
              if (process.env.REACT_APP_DEV) {
                online = true;
              }

              return (
                <>
                  {config.leftSidePanel.display && <LeftSideLayout2 />}

                  <div className="flex flex-col flex-auto min-w-0">
                    <main
                      id="fuse-main"
                      className="flex flex-col flex-auto min-h-screen min-w-0 relative"
                    >
                      {authRoles.user.some((_item) => _item === user.role) && (
                        <DescriptionAlerts online={online} />
                      )}

                      <GetCoordinates />
                      {config.navbar.display && (
                        <NavbarWrapperLayout2
                          className={clsx(config.navbar.style === 'fixed' && 'sticky top-0 z-50')}
                        />
                      )}

                      {searchText.length > 0 && <SearchAutocompleteMenu />}

                      {config.toolbar.display && <ToolbarLayout2 className={clsx('')} />}

                      <NavbarWrapperMobile />

                      {/* <div className="sticky top-0 z-99">
                <SettingsPanel />
              </div> */}

                      <div className={`flex flex-col flex-auto relative z-10 ${classes.content}`}>
                        <div className="relative">
                          <FuseDialog />

                          <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>

                          {props.children}
                        </div>
                      </div>

                      {config.footer.display && (
                        <FooterLayout2
                          className={config.footer.style === 'fixed' && 'md:sticky md:bottom-0'}
                        />
                      )}

                      <LoginAlert />
                      <ContactUserAlert />
                      <ChatAlert />
                      <ReportDialog />
                      <ShareAlert />
                    </main>
                  </div>

                  {config.rightSidePanel.display && user.role.length > 0 && <RightSideLayout2 />}
                  <FuseMessage />
                </>
              );
            }}
          />
        </div>
      )}
    </AppContext.Consumer>
  );
}

export default memo(Layout2);
