import { Button, Typography } from '@material-ui/core';
import { logoutUser } from 'app/auth/store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const MobileUserMenu = () => {
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();

  return (
    <div className="pt-20">
      {!user.role || user.role.length === 0 ? (
        <>
          <Typography align="center" className="mb-20">
            <Link to="/login">Login</Link>
          </Typography>
          <Typography align="center" className="mb-20">
            <Link to="/register">Register</Link>
          </Typography>
        </>
      ) : (
        <>
          <Typography align="center" className="mb-20">
            <Link to="/profile">My Profile</Link>
          </Typography>
          <Typography
            align="center"
            color='secondary'
            className="mb-20"
            onClick={() => {
              dispatch(logoutUser());
            }}
          >
            Logout
          </Typography>
        </>
      )}
    </div>
  );
};

export default MobileUserMenu;
