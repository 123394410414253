import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getReports = createAsyncThunk(
  "auth/reports/getReports",
  async (routeParams, { getState }) => {
    routeParams = routeParams || getState().auth.reports.routeParams;
    const response = await axios.get("/api/reports-app/reports", {
      params: routeParams,
    });
    const data = await response.data;

    return { data, routeParams };
  }
);

export const addReport = createAsyncThunk(
  "auth/reports/addReport",
  async (report, { dispatch, getState }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/reports`,
      report
    );
    const data = await response.data;

    return data;
  }
);

export const updateReport = createAsyncThunk(
  "auth/reports/updateReport",
  async (report, { dispatch, getState }) => {
    const response = await axios.post("/api/reports-app/update-report", { report });
    const data = await response.data;

    return data;
  }
);

export const removeReport = createAsyncThunk(
  "auth/reports/removeReport",
  async (reportId, { dispatch, getState }) => {
    await axios.post("/api/reports-app/remove-report", { reportId });

    return reportId;
  }
);

export const removeReports = createAsyncThunk(
  "auth/reports/removeReports",
  async (reportIds, { dispatch, getState }) => {
    await axios.post("/api/reports-app/remove-reports", { reportIds });

    return reportIds;
  }
);

export const toggleStarredReport = createAsyncThunk(
  "auth/reports/toggleStarredReport",
  async (reportId, { dispatch, getState }) => {
    const response = await axios.post("/api/reports-app/toggle-starred-report", {
      reportId,
    });
    const data = await response.data;

    dispatch(getReports());

    return data;
  }
);

export const toggleStarredReports = createAsyncThunk(
  "auth/reports/toggleStarredReports",
  async (reportIds, { dispatch, getState }) => {
    const response = await axios.post("/api/reports-app/toggle-starred-reports", {
      reportIds,
    });
    const data = await response.data;

    dispatch(getReports());

    return data;
  }
);

export const setReportsStarred = createAsyncThunk(
  "auth/reports/setReportsStarred",
  async (reportIds, { dispatch, getState }) => {
    const response = await axios.post("/api/reports-app/set-reports-starred", {
      reportIds,
    });
    const data = await response.data;

    dispatch(getReports());

    return data;
  }
);

export const setReportsUnstarred = createAsyncThunk(
  "auth/reports/setReportsUnstarred",
  async (reportIds, { dispatch, getState }) => {
    const response = await axios.post("/api/reports-app/set-reports-unstarred", {
      reportIds,
    });
    const data = await response.data;

    dispatch(getReports());

    return data;
  }
);

const reportsAdapter = createEntityAdapter({});

export const { selectAll: selectReports, selectById: selectReportsById } =
  reportsAdapter.getSelectors((state) => state.auth.reports);

const reportsSlice = createSlice({
  name: "auth/reports",
  initialState: reportsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    reportDialog: {
      type: "new",
      props: {
        open: false,
      },
      to: null,
      data: null,
    },
  }),
  reducers: {
    setReportsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewReportDialog: (state, action) => {
      state.reportDialog = {
        type: "new",
        props: {
          open: true,
        },
        to: action.payload,
        data: null,
      };
    },
    closeNewReportDialog: (state, action) => {
      state.reportDialog = {
        type: "new",
        props: {
          open: false,
        },
        to: null,
        data: null,
      };
    },
    openEditReportDialog: (state, action) => {
      state.reportDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditReportDialog: (state, action) => {
      state.reportDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [updateReport.fulfilled]: reportsAdapter.upsertOne,
    [addReport.fulfilled]: reportsAdapter.addOne,
    [removeReports.fulfilled]: (state, action) =>
      reportsAdapter.removeMany(state, action.payload),
    [removeReport.fulfilled]: (state, action) =>
      reportsAdapter.removeOne(state, action.payload),
    [getReports.fulfilled]: (state, action) => {
      const { data, routeParams } = action.payload;
      reportsAdapter.setAll(state, data);
      state.routeParams = routeParams;
      state.searchText = "";
    },
  },
});

export const {
  setReportsSearchText,
  openNewReportDialog,
  closeNewReportDialog,
  openEditReportDialog,
  closeEditReportDialog,
} = reportsSlice.actions;

export default reportsSlice.reducer;
