import { authRoles } from 'app/auth';
import { lazy } from 'react';

const PrivacyPolicyPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/privacy-policy',
      component: lazy(() => import(/* webpackChunkName: "Privacy Policy" */ './PrivacyPolicyPage')),
    },
  ],
};

export default PrivacyPolicyPageConfig;
