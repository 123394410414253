import { lazy } from 'react';

const FaqPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/pages/faq',
      component: lazy(() => import(/* webpackChunkName: "FAQ" */ './FaqPage')),
    },
  ],
};

export default FaqPageConfig;
