import { Popover } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import NavSearch from './NavSearch';

const MobileNavSearch = () => {
  const [userMenu, setUserMenu] = useState(null);
  const location = useLocation();

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  useEffect(() => {
    userMenuClose();
  }, [location.pathname]);

  return (
    <>
      <Search color="action" onClick={userMenuClick} />

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        <div className="mx-10">
          <NavSearch />
        </div>
      </Popover>
    </>
  );
};

export default MobileNavSearch;
