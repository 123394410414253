import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import PoweredByLinks from 'app/fuse-layouts/shared-components/PoweredByLinks';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import { Divider, Hidden, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function FooterLayout2(props) {
  const footerTheme = useSelector(selectFooterTheme);
  const country = useSelector(({ i18n }) => i18n.language);

  function Copyright() {
    return (
      <Typography className="mx-10" variant="body2" color="textSecondary" align="right">
        {'Copyright © '}
        <Link color="inherit" href="https://www.pdtzone.com">
          Progressive Digital Technologies
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className={clsx(props.className, 'relative z-20 shadow')}
        color="default"
        style={{ backgroundColor: footerTheme.palette.background.paper }}
      >
        <Toolbar className="container min-h-48 md:min-h-64 px-8 sm:px-12 py-0 block md:flex items-center overflow-x-auto">
          <div className="flex flex-grow flex-shrink-0 justify-center md:justify-start">
            <PoweredByLinks />
          </div>
          <Hidden mdDown>
            <div className="flex flex-grow flex-shrink-0 justify-end md:justify-end mb-20 md:mb-0">
              <Typography variant="body2" color="textSecondary" className="mr-12">
                <Link color="inherit" to="/privacy-policy" style={{ color: 'inherit' }}>
                  Privacy Policy
                </Link>
              </Typography>
              <Divider flexItem orientation="vertical" />
              <Typography variant="body2" color="textSecondary" className="mx-10">
                <Link color="inherit" to="/terms-and-conditions" style={{ color: 'inherit' }}>
                  Terms & Conditions
                </Link>
              </Typography>
              <Divider flexItem orientation="vertical" />
              <Typography variant="body2" color="textSecondary" className="mx-12">
                <Link
                  color="inherit"
                  to={'/pricing'.concat(`?iso2=${country}`)}
                  style={{ color: 'inherit' }}
                >
                  Pricing
                </Link>
              </Typography>
              <Divider flexItem orientation="vertical" />
              <Copyright />
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className="flex flex-grow flex-shrink-0 justify-end md:justify-start mb-20 md:mb-0">
              <Copyright />
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(FooterLayout2);
