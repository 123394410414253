import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getServices = createAsyncThunk('auth/services/getServices', async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/services`, { params });
  const data = await response.data;

  return data;
});

export const removeServices = createAsyncThunk(
  'auth/services/removeServices',
  async (serviceIds, { dispatch, getState }) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/services/remove-services`, {
      serviceIds,
    });

    return serviceIds;
  }
);

const servicesAdapter = createEntityAdapter({});

export const { selectAll: selectServices, selectById: selectServiceById } =
  servicesAdapter.getSelectors((state) => state.auth.services);

const servicesSlice = createSlice({
  name: 'auth/services',
  initialState: servicesAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setServicesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getServices.fulfilled]: servicesAdapter.setAll,
    [removeServices.fulfilled]: (state, action) => servicesAdapter.removeMany(state, action.payload),
  },
});

export const { setServicesSearchText } = servicesSlice.actions;

export default servicesSlice.reducer;
