import FuseShortcuts from '@fuse/core/FuseShortcuts';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ChatPanelToggleButton from 'app/fuse-layouts/shared-components/chatPanel/ChatPanelToggleButton';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import QuickPanelToggleButton from 'app/fuse-layouts/shared-components/quickPanel/QuickPanelToggleButton';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import MobileNavSearch from 'app/fuse-layouts/shared-components/MobileNavSearch';
import CountrySwitcher from 'app/fuse-layouts/shared-components/CountrySwitcher';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import AdjustFontSize from '../../shared-components/AdjustFontSize';
import FullScreenToggle from '../../shared-components/FullScreenToggle';
import NotificationPanelToggleButton from '../../shared-components/notificationPanel/NotificationPanelToggleButton';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function ToolbarLayout2(props) {
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const toolbarTheme = useSelector(selectToolbarTheme);

  const classes = useStyles(props);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx(
          classes.root,
          'flex relative md:sticky md:top-64 z-20 shadow-md md:pt-2',
          props.className
        )}
        color="default"
        style={{ backgroundColor: toolbarTheme.palette.background.paper }}
      >
        <Toolbar className="container p-0 lg:px-24 min-h-48 md:min-h-48">
          <Hidden lgUp>
            <div className="flex flex-shrink-0 items-center">
              <Logo />
            </div>
          </Hidden>

          <div className="flex flex-1">
            <Hidden mdDown>
              <FuseShortcuts />
            </Hidden>
          </div>

          <div className="flex items-center px-8 h-full overflow-x-auto">
            <Hidden lgUp>
              <MobileNavSearch />
            </Hidden>

            <Hidden lgDown>
              <CountrySwitcher />
            </Hidden>

            <Hidden mdDown>
              <AdjustFontSize />

              <FullScreenToggle />
            </Hidden>

            <Hidden lgUp>
              <ChatPanelToggleButton />
            </Hidden>

            <QuickPanelToggleButton />

            <NotificationPanelToggleButton />

            <Hidden lgUp>
              <CountrySwitcher />
            </Hidden>
          </div>

          {config.navbar.display && (
              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 ml-5" />
              </Hidden>
            )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default ToolbarLayout2;
