import { yupResolver } from '@hookform/resolvers/yup';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import _ from '@lodash';
import * as yup from 'yup';

import {
  closeNewReportDialog,
  closeEditReportDialog,
  addReport,
  updateReport,
} from 'app/auth/store/reportsSlice';
import { authRoles } from 'app/auth';
import { openLoginAlertDialog } from 'app/auth/store/userSlice';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  problem: yup.string().required('You must enter a problem'),
});

function ReportDialog(props) {
  const dispatch = useDispatch();
  const [toUser, setToUser] = useState();
  const reportDialog = useSelector(({ auth }) => auth.reports.reportDialog);

  const user = useSelector(({ auth }) => auth.user);

  const defaultValues = {
    from: user._id,
    problem: '',
  };

  const { control, watch, reset, handleSubmit, formState, getValues, register } = useForm({
    mode: 'onChange',
    defaultValues,
    ...user, // Default values
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (reportDialog.type === 'edit' && reportDialog.data) {
      reset({ ...reportDialog.data });
    }

    /**
     * Dialog type: 'new'
     */
    if (reportDialog.type === 'new') {
      reset({
        ...defaultValues,
        ...reportDialog.data,
      });
    }
  }, [reportDialog.type, reportDialog.data, reset, defaultValues]);

  /**
   * On Dialog Open
   */
  useEffect(() => {
    if (reportDialog.props.open) {
      initDialog();
    }
  }, [reportDialog.props.open]);

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    return reportDialog.type === 'edit'
      ? dispatch(closeEditReportDialog())
      : dispatch(closeNewReportDialog());
  }

  /**
   * Form Submit
   */
  const onSubmit = async (data) => {
    if (!authRoles.user.some((_item) => _item === user.role)) {
      dispatch(openLoginAlertDialog());
      closeComposeDialog();
      return;
    }

    const report = { ...data, problemUser: toUser };

    if (reportDialog.type === 'new') {
      dispatch(addReport(report));
    } else {
      dispatch(updateReport({ ...reportDialog.data, ...report }));
    }
    closeComposeDialog();
    window.location.reload();
  };

  /**
   * Remove Event
   */
  function handleRemove() {
    // dispatch(removeReport(id));
    closeComposeDialog();
  }

  useEffect(() => {
    setToUser(reportDialog.to?._id);

    return () => {};
  }, [reportDialog.to]);

  return (
    <Dialog
      classes={{
        paper: 'm-24',
      }}
      {...reportDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="sm"
    >
      <AppBar position="static" elevation={0} color="inherit">
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {reportDialog.type === 'new'
              ? 'Report a problem and our team will get to it within 24 hours'
              : 'Edit Report'}
          </Typography>
        </Toolbar>
      </AppBar>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col md:overflow-hidden"
      >
        <DialogContent classes={{ root: 'p-24 pt-0' }}>
          <div className="flex">
            <Controller
              control={control}
              name="problem"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Problem"
                  id="problem"
                  error={!!errors.problem}
                  helperText={errors?.problem?.message}
                  variant="outlined"
                  fullWidth
                  required
                  multiline
                  minRows={10}
                />
              )}
            />
          </div>
        </DialogContent>

        {reportDialog.type === 'new' ? (
          <DialogActions className="justify-between p-4 pb-16">
            <div className="px-16 ">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={_.isEmpty(dirtyFields) || !isValid}
              >
                Submit
              </Button>
            </div>
          </DialogActions>
        ) : (
          <DialogActions className="justify-between p-4 pb-16">
            <div className="px-16">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={_.isEmpty(dirtyFields) || !isValid}
              >
                Save
              </Button>
            </div>
            <IconButton onClick={handleRemove}>
              <Icon>delete</Icon>
            </IconButton>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
}

export default ReportDialog;
