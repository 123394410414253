import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getTrades = createAsyncThunk('auth/trades/getTrades', async (params, { dispatch }) => {
  dispatch(setLoading(true));
  const response = await axios
    .get(`${process.env.REACT_APP_API_URL}/v1/trades`, { params })
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    });
  const data = await response.data;

  return data;
});

export const removeTrades = createAsyncThunk(
  'auth/trades/removeTrades',
  async (tradeIds, { dispatch, getState }) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/trades/remove-trades`, {
      tradeIds,
    });

    return tradeIds;
  }
);

const tradesAdapter = createEntityAdapter({});

export const { selectAll: selectTrades, selectById: selectTradeById } = tradesAdapter.getSelectors(
  (state) => state.auth.trades
);

const tradesSlice = createSlice({
  name: 'auth/trades',
  initialState: tradesAdapter.getInitialState({
    searchText: '',
    loading: true,
  }),
  reducers: {
    setTradesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setLoading: {
      reducer: (state, action) => {
        state.loading = action.payload;
      },
    },
  },
  extraReducers: {
    [getTrades.fulfilled]: tradesAdapter.setAll,
    [removeTrades.fulfilled]: (state, action) => tradesAdapter.removeMany(state, action.payload),
  },
});

export const { setTradesSearchText, setLoading } = tradesSlice.actions;

export default tradesSlice.reducer;
