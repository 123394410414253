import { authRoles } from 'app/auth';
import { lazy } from 'react';

const ProfilePageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/profile/:userId",
      component: lazy(() => import(/* webpackChunkName: "Profile" */ "./ProfilePage")),
      exact: true
    },
  ],
};

export default ProfilePageConfig;
