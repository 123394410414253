import { lazy } from 'react';

const ResultItemRoutes = [
  {
    path: "/results/industries/:industryId/item/:adId",
    component: lazy(() =>
      import(/* webpackChunkName: "Item More Info Page" */ "./products/Product")
    ),
  },
];

export default ResultItemRoutes;
