import { lazy } from "react";

const PricingStyle1PageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/pricing",
      component: lazy(() =>
        import(/* webpackChunkName: "Pricing" */ "./PricingStyle1Page")
      ),
    },
  ],
};

export default PricingStyle1PageConfig;
