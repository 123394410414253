import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getJobClassifications = createAsyncThunk(
  'auth/jobClassifications/getJobClassifications',
  async (params, { dispatch, getState }) => {
    dispatch(setLoading(true));
    const response = await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/job-classifications`, { params })
      .then((res) => {
        dispatch(setLoading(false));
        return res;
      });
    const data = await response.data;

    return data;
  }
);

export const removeJobClassifications = createAsyncThunk(
  'auth/jobClassifications/removeJobClassifications',
  async (industryIds, { dispatch, getState }) => {
    await axios.post('/api/jobClassifications/remove-jobClassifications', { industryIds });

    return industryIds;
  }
);

const jobClassificationsAdapter = createEntityAdapter({});

export const { selectAll: selectJobClassifications, selectById: selectIndustryById } =
  jobClassificationsAdapter.getSelectors((state) => state.auth.jobClassifications);

const jobClassificationsSlice = createSlice({
  name: 'auth/jobClassifications',
  initialState: jobClassificationsAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setJobClassificationsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setLoading: {
      reducer: (state, action) => {
        state.loading = action.payload;
      },
    },
  },
  extraReducers: {
    [getJobClassifications.fulfilled]: jobClassificationsAdapter.setAll,
    [removeJobClassifications.fulfilled]: (state, action) =>
      jobClassificationsAdapter.removeMany(state, action.payload),
  },
});

export const { setJobClassificationsSearchText, setLoading } = jobClassificationsSlice.actions;

export default jobClassificationsSlice.reducer;
