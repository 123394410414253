import { authRoles } from "app/auth";
import { lazy } from "react";

const SubscriptionsPageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/subscriptions/:priceId",
      component: lazy(() =>
        import(
          /* webpackChunkName: "Subscriptions Check Out" */ "./CheckOutForm"
        )
      ),
    },
  ],
};

export default SubscriptionsPageConfig;
