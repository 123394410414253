import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCategories = createAsyncThunk(
  'auth/categories/getCategories',
  async (params, { dispatch, getState }) => {
    dispatch(resetCategories());
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/categories`, { params });
    const data = await response.data;

    return data;
  }
);

export const removeCategories = createAsyncThunk(
  'auth/categories/removeCategories',
  async (categoryIds, { dispatch, getState }) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/categories/remove-categories`, {
      categoryIds,
    });

    return categoryIds;
  }
);

const categoriesAdapter = createEntityAdapter({});

export const { selectAll: selectCategories, selectById: selectCategoryById } =
  categoriesAdapter.getSelectors((state) => state.auth.categories);

const categoriesSlice = createSlice({
  name: 'auth/categories',
  initialState: categoriesAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setCategoriesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    resetCategories: {
      reducer: (state, action) => {
        state.ids = [];
        state.entities = [];
      },
    },
  },
  extraReducers: {
    [getCategories.fulfilled]: categoriesAdapter.setAll,
    [removeCategories.fulfilled]: (state, action) =>
      categoriesAdapter.removeMany(state, action.payload),
  },
});

export const { setCategoriesSearchText, resetCategories } = categoriesSlice.actions;

export default categoriesSlice.reducer;
