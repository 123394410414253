import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { closeLoginAlertDialog } from 'app/auth/store/userSlice';
import History from '@history';

export default function LoginAlert() {
  const [open, setOpen] = React.useState(false);
  const dialog = useSelector(({ auth }) => auth.user.loginAlertDialog);
  const dispatch = useDispatch();
  const country = useSelector(({ i18n }) => i18n.language);

  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    dispatch(closeLoginAlertDialog());
  }

  return (
    <Dialog
      {...dialog?.props}
      onClose={closeComposeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: 'm-24 rounded-8',
      }}
    >
      <DialogTitle id="alert-dialog-title">Are you a registered User?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please login to your account to continue to use this service. If you don't have an account register
          and login to enjoy many Pdtzone services.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            History.push(
              `/register?redirect=${window.location.pathname}&iso2=${country}`
            );
            closeComposeDialog();
          }}
          color="primary"
        >
          Register
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            History.push(
              `/login?redirect=${window.location.pathname}&iso2=${country}`
            );
            closeComposeDialog();
          }}
          color="primary"
          autoFocus
        >
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}
