import React from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import { Row, Column, Item } from '@mui-treasury/components/flex';
import { useSizedIconButtonStyles } from '@mui-treasury/styles/iconButton/sized';
import { Button, CircularProgress, Divider, IconButton } from '@material-ui/core';
import TextInfoContent from '@mui-treasury/components/content/textInfo/TextInfoContent';
import { CallMade, FiberManualRecord } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { formatDistanceToNow } from 'date-fns';
import { useParams } from 'react-router';
import { Country } from 'country-state-city';

const StyledTooltip = withStyles({
  tooltip: {
    marginTop: '0.2rem',
    backgroundColor: 'rgba(0,0,0,0.72)',
    color: '#fff',
  },
})(Tooltip);

const useBasicProfileStyles = makeStyles(({ palette }) => ({
  avatar: {
    borderRadius: 8,
    backgroundColor: '#000',
    opacity: '0.9',
  },
  overline: {
    fontSize: 10,
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const BasicProfile = (props) => {
  const styles = useBasicProfileStyles();
  const ad = useSelector(({ auth }) => auth.ad);
  const user = useSelector(({ auth }) => auth.user);

  return (
    <Row {...props}>
      <Item>
        {user?.picturePath?.length > 0 ? (
          <Avatar
            className={`${styles.avatar}`}
            alt="user photo"
            src={
              user?.picturePath.startsWith('avatars/')
                ? `${process.env.REACT_APP_FILE_STORAGE_URL}/${user?.picturePath}`
                : user?.picturePath
            }
          />
        ) : (
          <Avatar className={styles.avatar}>
            {user?.name?.charAt(0)}
            {user?.lastName?.charAt(0)}
          </Avatar>
        )}
      </Item>
      <Item position="middle" pl={{ sm: 0.5, lg: 0.5 }}>
        <Typography className={styles.overline}>Business Manager</Typography>
        <Typography className={styles.name}>
          {user?.name} {user?.lastName}
        </Typography>
      </Item>
    </Row>
  );
};

const useCardHeaderStyles = makeStyles(() => ({
  root: { paddingBottom: 0 },
  title: {
    fontSize: '2rem',
    width: 'inherit',
  },
  subheader: {
    fontSize: '0.875rem',
    color: '#495869',
  },
  price: {
    fontSize: '2rem',
    textTransform: 'uppercase',
  },
  textLight: {
    opacity: 0.87,
    fontWeight: 500,
    fontSize: 14,
  },
  text: {
    opacity: 0.87,
    fontWeight: 500,
    fontSize: 14,
  },
  textBold: {
    color: '#000',
  },
  card: {
    border: '2px solid',
    borderColor: '#E7EDF3',
    transition: '0.4s',
    '&:hover': {
      // borderColor: '#5B9FED',
    },
  },
}));

const DetailsCard = (props) => {
  const styles = useCardHeaderStyles();
  const iconBtnStyles = useSizedIconButtonStyles({ padding: 8, childSize: 20 });
  const theme = useTheme();
  const { ad } = props;
  const dispatch = useDispatch();

  return (
    <Row {...props}>
      <Item position="start">
        <TextInfoContent
          heading={
            <>
              <Typography variant="h3" className={`${styles.title} text-28`}>
                {ad.name}
              </Typography>
              {Object.keys(ad.jobAttributes || {})?.length > 0 && (
                <Grid container spacing={1} className="mt-20">
                  {Object.keys(ad.jobAttributes || {})?.map((key) => {
                    if (ad.jobAttributes[key]?.length > 0) {
                      return (
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="body1"
                            className={`${styles.textLight} font-400`}
                          >
                            {key} :{" "}
                            <span className={`${styles.textBold} font-500`}>
                              {ad.jobAttributes[key]}
                            </span>
                          </Typography>
                        </Grid>
                      );
                    }

                    return null;
                  })}

                  <Grid item xs={12} md={12} className="mt-16">
                    <Typography paragraph>
                      <Divider />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography
                      variant="body1"
                      className={`${styles.text} md:mt-6`}
                    >
                      Date Posted
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography
                      variant="body1"
                      className={`${styles.text} md:text-right`}
                    >
                      {ad.companyName ? `${ad.companyName} ` : ""}
                      {ad.companyName?.length && (
                        <FiberManualRecord className="text-10" />
                      )}{" "}
                      {ad.state?.name && ad.state?.name}
                      {ad.state?.name && ad.city?.name && `,`}{" "}
                      {ad.city?.name && ad.city?.name}{" "}
                      {ad.country?.length > 0 && (
                        <IconButton
                          className="bg-grey-200"
                          // onClick={() => dispatch(changeLanguage(ad.country))}
                        >
                          <span className="min-w-20">
                            {Country.getCountryByCode(ad.country)}
                          </span>
                        </IconButton>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </>
          }
          body={
            <Grid container spacing={0}>
              <Grid item xs={12} md={12}>
                <Typography className="my-20">
                  <Divider />
                </Typography>
              </Grid>

              <Typography variant="h3" className={styles.title}>
                About the Job
              </Typography>

              <Typography
                className={`${styles.text} my-24 leading-normal md:min-w-lg`}
                variant="body1"
                dangerouslySetInnerHTML={{ __html: ad.message }}
              />

              {ad.link?.length > 0 && (
                <>
                  <Grid item xs={12} md={12}>
                    <Typography paragraph>
                      <Divider />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className="flex justify-end">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => window.open(ad.link, "_blank")}
                    >
                      Apply for this Job
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          }
        />
      </Item>
    </Row>
  );
};

const CompanyCard = (props) => {
  const styles = useCardHeaderStyles();
  const iconBtnStyles = useSizedIconButtonStyles({ padding: 8, childSize: 20 });
  const theme = useTheme();
  const ad = useSelector(({ auth }) => auth.ad);
const user = useSelector(({ auth }) => auth.user);

  return (
    <Row {...props}>
      <Item position="start">
        <TextInfoContent
          heading={
            <Typography variant="h3" className={styles.title} paragraph>
              {user?.companyName}
            </Typography>
          }
        />
      </Item>
      <Item position="right" mr={-0.5}>
        <StyledTooltip title="See details">
          <IconButton
            classes={iconBtnStyles}
            // onClick={() => History.push(`/profile/${user?._id}`)}
          >
            <CallMade />
          </IconButton>
        </StyledTooltip>
      </Item>
    </Row>
  );
};

const useStyles = makeStyles(() => ({
  card: {
    border: '2px solid',
    borderColor: '#E7EDF3',
    borderRadius: 4,
    transition: '0.4s',
    '&:hover': {
      // borderColor: '#5B9FED',
    },
  },
}));

export const ShowcaseCardDemo = React.memo(function ShowcaseCard({ ad }) {
  const styles = useStyles();
  const gap = { xs: 1, sm: 1.5, lg: 2 };
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { industryId } = routeParams;

  if (ad === null || !ad) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={3} className="bg-white rounded-3">
      <Grid item xs={12} md={10}>
        <Row className={styles.card} gap={gap}>
          <Grid item container>
            <Column>
              <DetailsCard ad={ad} />
            </Column>
          </Grid>
        </Row>
      </Grid>
      <Grid item xs={12} md={2}>
        <Column className={styles.card} gap={gap}>
          {user.role !== 'ldm-admin' && (
            <>
              <CompanyCard />
              <BasicProfile />
            </>
          )}

          {ad.link?.length > 0 && (
            <Item position="middle" pl={{ sm: 0.5, lg: 0.5 }}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => window.open(ad.link, '_blank')}
              >
                Apply for this Job
              </Button>
            </Item>
          )}
          <Item position="middle" pl={{ sm: 0.5, lg: 0.5 }}>
            <Button
              fullWidth
              style={{ background: 'rgba(100, 100, 100, 0.7)', color: '#fff' }}
              color="primary"
              variant="contained"
              // onClick={() =>
              //   dispatch(
              //     openShareAlertDialog(
              //       `/results/industries/${industryId}/item/${ad._id}`
              //     )
              //   )
              // }
            >
              Share this Job Advert
            </Button>
          </Item>
          {user.role !== 'ldm-admin' && (
            <Item position="middle" pl={{ sm: 0.5, lg: 0.5 }}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                // onClick={() => dispatch(openContactDetailsAlertDialog(ad))}
              >
                Contact Manager
              </Button>
            </Item>
          )}

          <Item
            className={user._id === user?._id ? 'hidden' : ''}
            position="middle"
            pl={{ sm: 0.5, lg: 0.5 }}
          >
            <Button
              fullWidth
              className={
                user?.requests?.some((req) => req === user._id) ||
                user.connections?.some((conn) => conn._id === user?._id)
                  ? 'hidden'
                  : ''
              }
              style={{ background: 'rgba(255, 0, 0, 0.7)', color: '#fff' }}
              disabled={
                user?.requests?.some((req) => req === user._id) ||
                user.connections?.some((conn) => conn._id === user?._id)
              }
              color="primary"
              variant="contained"
              // onClick={(ev) => sendConnectionRequest()}
            >
              Request to chat with Manager
            </Button>

            <Button
              fullWidth
              className={`${
                !(
                  !user?.requests?.some((req) => req === user._id) &&
                  user.connections?.some((conn) => conn._id === user?._id)
                )
                  ? 'hidden'
                  : ''
              }`}
              style={{ background: 'rgba(255, 0, 0, 0.7)', color: '#fff' }}
              color="primary"
              variant="contained"
              // onClick={() => {
              //   dispatch(openChatPanel());
              //   dispatch(getChat({ contactId: user?._id }));
              // }}
            >
              Chat Now
            </Button>
          </Item>
        </Column>
      </Grid>
    </Grid>
  );
});
export default ShowcaseCardDemo;
