import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function DescriptionAlerts({ online }) {
  const classes = useStyles();
  const user = useSelector(({ auth }) => auth.user);
  const [close, setClose] = useState(false);

  if (close) return null;

  if (!online) {
    return (
      <div className={classes.root}>
        <Alert onClose={() => setClose(true)} severity="error">
          You're offline right now. Check your connection.
        </Alert>
      </div>
    );
  }

  if (user?.role === "pdm-user") {
    return (
      <div className={classes.root}>
        <Alert onClose={() => setClose(true)} severity="info">
          You are registered as an ordinary user. Tell other users if you are a
          recruiter, employer, labour supplier or job seeker to get more out of
          this platform. —{" "}
          <Link to="/apps/profile?tab=1">
            <strong>Edit your profile!</strong>
          </Link>
        </Alert>
      </div>
    );
  }

  if (
    user?.industries?.length === 0 ||
    user?.sectors?.length === 0 ||
    user?.jobCategories?.length === 0 ||
    user?.jobTypes?.length === 0 ||
    user?.trades?.length === 0
  ) {
    return (
      <div className={classes.root}>
        <Alert onClose={() => setClose(true)} severity="info">
          The work section in your profile is not complete. This information
          helps get your profile to the right users and organisations. —{" "}
          <Link to="/apps/profile?tab=1">
            <strong>Edit your profile!</strong>
          </Link>
        </Alert>
      </div>
    );
  }

  if (
    user?.role === "ldm-job-seeker" &&
    (!user?.jobAttributes ||
      Object.keys(user?.jobAttributes)?.length === 0 ||
      !Object.keys(user?.jobAttributes)?.some(
        (key) => user.jobAttributes[key].length > 0
      ))
  ) {
    return (
      <div className={classes.root}>
        <Alert onClose={() => setClose(true)} severity="info">
          Your job attributes are incomplete. This information helps get your
          profile to the right employers and recruiters. —{" "}
          <Link to="/apps/profile?tab=1">
            <strong>Edit your profile!</strong>
          </Link>
        </Alert>
      </div>
    );
  }

  if (
    user?.phone?.length === 0 ||
    user?.address?.length === 0 ||
    user?.website?.length === 0 ||
    !user?.phone ||
    !user?.address ||
    !user?.website
  ) {
    return (
      <div className={classes.root}>
        <Alert onClose={() => setClose(true)} severity="info">
          Your contact information is incomplete. —{" "}
          <Link to="/apps/profile?tab=2">
            <strong>Edit your profile!</strong>
          </Link>
        </Alert>
      </div>
    );
  }

  if (!user?.socials || Object.keys(user?.socials)?.length === 0) {
    return (
      <div className={classes.root}>
        <Alert onClose={() => setClose(true)} severity="info">
          Your can share your social media handles in your profile for other
          users to learn more about you. —{" "}
          <Link to="/apps/profile?tab=3">
            <strong>Edit your profile!</strong>
          </Link>
        </Alert>
      </div>
    );
  }

  return null;
}
