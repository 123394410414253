import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getNotifications = createAsyncThunk('notificationPanel/data/getData', async () => {
  const response = await axios.get('/api/notification-panel/data');
  const data = await response.data;

  return data;
});

export const updateUser = createAsyncThunk(
  'usersApp/users/updateUser',
  async (user, { dispatch, getState }) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/register`, user);
    const data = await response.data;

    return data;
  }
);

const notificationsAdapter = createEntityAdapter({});

const initialState = notificationsAdapter.upsertMany(notificationsAdapter.getInitialState(), []);

export const { selectAll: selectNotifications, selectById: selectNotificationsById } =
  notificationsAdapter.getSelectors((state) => state.notificationPanel.data);

const dataSlice = createSlice({
  name: 'notificationPanel/data',
  initialState,
  reducers: {
    dismissItem: (state, action) => {
      const { user, id } = action.payload;

      const updatedUser = { ...user };
      updatedUser.notifications = user?.notifications.filter((item) => item._id !== id);

      axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/register`, updatedUser);

      return notificationsAdapter.removeOne(state, id);
    },
    dismissAll: (state, action) => {
      const { user } = action.payload;

      const updatedUser = { ...user };
      updatedUser.notifications = [];

      axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/register`, updatedUser);

      return notificationsAdapter.removeAll(state);
    },
    addNotification: (state, action) => notificationsAdapter.addOne(state, action.payload),
  },
  extraReducers: {
    [getNotifications.fulfilled]: (state, action) =>
      notificationsAdapter.addMany(state, action.payload),
  },
});

export const { dismissItem, dismissAll, addNotification } = dataSlice.actions;

export default dataSlice.reducer;
