import { authRoles } from "app/auth";
import i18next from "i18next";
import { lazy } from "react";
import { Redirect } from "react-router-dom";
import ar from "./i18n/ar";
import en from "./i18n/en";
import tr from "./i18n/tr";

i18next.addResourceBundle("en", "mailApp", en);
i18next.addResourceBundle("tr", "mailApp", tr);
i18next.addResourceBundle("ar", "mailApp", ar);

const UserJobPostsAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: [
        "/job-posts/my-posts/country/:countryName/:countryId/filter/:filter",
        "/job-posts/my-posts/filter/:filter/:filterName/:filterId",
        "/job-posts/my-posts/filter/:filter",
        "/job-posts/my-posts/:postId",
        "/job-posts/my-posts",
      ],
      component: lazy(() =>
        import(/* webpackChunkName: "User Posts" */ "./MailApp")
      ),
    },
    {
      path: "/job-posts/my-posts",
      component: () => <Redirect to="/job-posts/my-posts" />,
    },
  ],
};

export default UserJobPostsAppConfig;
