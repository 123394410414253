import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import _ from '@lodash';
import WYSIWYGEditor from 'app/shared-components/WYSIWYGEditor';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { getJobAttributes, selectJobAttributes } from 'app/auth/store/jobAttributesSlice';
import { openLoginAlertDialog } from 'app/auth/store/userSlice';
import withReducer from 'app/store/withReducer';
import { authRoles } from 'app/auth';
import { City, Country, State } from 'country-state-city';
import {
  AppBar,
  ButtonBase,
  CardContent,
  Grid,
  InputAdornment,
  makeStyles,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Toolbar,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { amber, blue, blueGrey, brown, green, purple } from '@material-ui/core/colors';
import { useTheme } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import { saveMail } from './store/mailSlice';
import { getIndustries, selectIndustries } from './store/industriesSlice';
import { getTrades, selectTrades } from './store/tradesSlice';
import { getJobTypes, selectJobTypes } from './store/jobTypesSlice';
import { getJobCategories, selectJobCategories } from './store/jobCategoriesSlice';
import { getSectors, selectSectors } from './store/sectorsSlice';
import reducer from './store';
import { ShowcaseCardDemo } from './ShowcaseCardDemo';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  option: {
    '& > span': {
      marginRight: 10,
    },
  },
}));

/**
 *  Get Steps
 */
function getSteps() {
  return [
    'Who do you want to apply to your ad?',
    'What are you looking for from an applicant?',
    'Job vacancy details',
    'About the job',
    'Ad Preview',
  ];
}

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  name: yup.string().required('You must enter a title for your post'),
  message: yup.string().required('You must enter a post'),
});

function MailCompose() {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();
  const steps = getSteps();
  const [industriesList, setIndustriesList] = useState([]);
  const [sectorsList, setSectorsList] = useState([]);
  const [jobCategoriesList, setJobCategoriesList] = useState([]);
  const [jobTypesList, setJobTypesList] = useState([]);
  const [tradesList, setTradesList] = useState([]);
  const attributes = useSelector(selectJobAttributes);
  const industries = useSelector(selectIndustries);
  const sectors = useSelector(selectSectors);
  const jobCategories = useSelector(selectJobCategories);
  const jobTypes = useSelector(selectJobTypes);
  const trades = useSelector(selectTrades);
  const [states, setStates] = useState();
  const [cities, setCities] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [searchText, setSearchText] = useState('');
  const countries = Country.getAllCountries();

  const materialDate = () => {
    const dateNow = new Date(); // Creating a new date object with the current date and time
    const year = dateNow.getFullYear(); // Getting current year from the created Date object
    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const month = // Setting current Month number from current Date object
      monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${monthWithOffset}`
        : monthWithOffset;
    const date =
      dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${dateNow.getUTCDate()}`
        : dateNow.getUTCDate();

    const materialDateInput = `${year}-${month}-${date}`; // combining to format for defaultValue or value attribute of material <TextField>
    return materialDateInput;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const { handleSubmit, formState, control, getValues, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      from: user._id,
      industries: [],
      sectors: [],
      jobCategories: [],
      jobTypes: [],
      trades: [],
      name: '',
      message: '',
      link: '',
      country: {},
      state: {},
      city: {},
      dueDate: materialDate(),
      companyName: user.companyName,
    },
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const selectedIndustries = watch('industries');
  const selectedSector = watch('sectors');
  const selectedJobType = watch('jobTypes');
  const selectedTrades = watch('trades');

  const { t } = useTranslation('mailApp');

  function handleOpenDialog() {
    setOpenDialog(true);
  }

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  function handleDelete() {
    setOpenDialog(false);
  }

  const onSubmit = async (data) => {
    const tradeIndustries = [];
    const tradeSectors = [];
    const tradeJobCategories = [];
    const tradeJobTypes = [];

    data.trades?.forEach((_item) => {
      _item.industries?.forEach((_industry) => {
        if (_.some(tradeIndustries, _industry)) return;

        tradeIndustries.push(_industry);
      });
      _item.sectors?.forEach((_sector) => {
        if (_.some(tradeSectors, _sector)) return;
        tradeSectors.push(_sector);
      });

      if (_item.jobCategory) tradeJobCategories.push(_item.jobCategory?._id);
      if (_item.jobType) tradeJobTypes.push(_item.jobType?._id);
    });

    data.industries = _.uniq(tradeIndustries);
    data.sectors = _.uniq(tradeSectors);
    data.jobCategories = _.uniq(tradeJobCategories);
    data.jobTypes = _.uniq(tradeJobTypes);
    data.trades = data.trades.map((item) => item._id);
    data.country = data.country.isoCode;

    dispatch(saveMail(data)).then(() => {
      setOpenDialog(false);
      window.location.reload();
    });
  };

  const handleChange = (e) => {
    setSearchText(e.target?.value);
  };

  useEffect(() => {
    setIndustriesList(industries);

    return () => {};
  }, [industries]);

  useEffect(() => {
    dispatch(getIndustries());
    dispatch(getSectors());
    dispatch(getJobCategories());
    dispatch(getJobTypes());
    dispatch(getTrades());
    dispatch(getJobAttributes());

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    setTradesList(trades);
    return () => {};
  }, [trades]);

  const TradeCard = ({ trade }) => {
    const theme = useTheme();

    const colors = [
      { color: blue[500] },
      { color: amber[500] },
      { color: blueGrey[500] },
      { color: green[500] },
      { color: purple[500] },
      { color: brown[500] },
    ];

    const randomColor = colors[Math.floor(Math.random() * colors.length)];

    return (
      <ButtonBase
        className="w-full "
        onClick={() => {
          if (_.some(selectedTrades, { _id: trade._id })) {
            setValue('trades', [..._.filter(selectedTrades, { _id: trade._id })]);
          } else {
            setValue('trades', [...selectedTrades, trade]);
          }
        }}
      >
        <Card
          className={`h-84 shadow cursor-pointer ${
            _.some(selectedTrades, { _id: trade._id }) ? 'border-4 border-solid border-red-700' : ''
          }`}
          style={{
            width: 'inherit',
            background: randomColor.color,
            color: theme.palette.getContrastText(randomColor.color),
          }}
        >
          <CardContent>
            <Typography className="font-medium text-center" color="inherit">
              {trade?.name}
            </Typography>
          </CardContent>
        </Card>
      </ButtonBase>
    );
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={(ev) => {
          if (authRoles.user.some((_item) => _item === user.role)) {
            handleOpenDialog();
          } else {
            dispatch(openLoginAlertDialog());
          }
        }}
        className="font-semibold mr-10 bg-red-500 text-white"
        size="small"
      >
        Post an Ad
      </Button>

      <Dialog
        open={openDialog}
        onClose={() => handleCloseDialog()}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        className="min-w-400"
      >
        <AppBar position="static" elevation={0}>
          <Toolbar className="flex w-full">
            <Typography variant="subtitle1" color="inherit">
              New Job Post{' '}
              {selectedTrades?.length > 0 ? ' for '.concat(selectedTrades[0]?.name) : ''}
            </Typography>
          </Toolbar>
        </AppBar>

        <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    <div className="mt-32">
                      {index === 0 && (
                        <>
                          <div className={classes.actionsContainer}>
                            <div className="mt-20">
                              <Button
                                disabled={
                                  selectedTrades?.length === 0 || selectedTrades === undefined
                                }
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                                className={classes.button}
                              >
                                Next
                              </Button>
                            </div>
                            {/* <pre>{JSON.stringify(form, null, 2)}</pre> */}
                          </div>

                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <Controller
                                name="industries"
                                control={control}
                                defaultValue={[]}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    className="mt-8 mb-16"
                                    multiple
                                    options={industriesList || ['']}
                                    getOptionLabel={(option) => option?.name || ''}
                                    value={value}
                                    onChange={(event, newValue) => {
                                      setSectorsList([]);
                                      setJobCategoriesList([]);
                                      setJobTypesList([]);
                                      setValue('sectors', []);
                                      setValue('jobCategories', []);
                                      setValue('jobTypes', []);
                                      setValue('trades', []);

                                      onChange(newValue);

                                      if (newValue.length > 0) {
                                        setSectorsList(
                                          sectors.filter((sector) =>
                                            newValue.some(
                                              (industry) => industry?._id === sector.industry?._id
                                            )
                                          )
                                        );

                                        setTradesList(
                                          tradesList.filter((trade) =>
                                            newValue.some((industry) =>
                                              trade.industries?.some(
                                                (_i) => _i._id === industry._id
                                              )
                                            )
                                          )
                                        );
                                      } else {
                                        setTradesList(trades);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select industries you want to apply to your ad"
                                        label="Industries"
                                        variant="outlined"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Controller
                                name="sectors"
                                control={control}
                                defaultValue={[]}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    className="mt-8 mb-16"
                                    multiple
                                    options={sectorsList || ['']}
                                    groupBy={(option) => option.industry.name}
                                    getOptionLabel={(option) => option?.name || ''}
                                    value={value}
                                    onChange={(event, newValue) => {
                                      setJobCategoriesList([]);
                                      setJobTypesList([]);
                                      setTradesList([]);
                                      setValue('jobCategories', []);
                                      setValue('jobTypes', []);
                                      setValue('trades', []);

                                      onChange(newValue);

                                      if (newValue.length > 0) {
                                        setJobCategoriesList(
                                          jobCategories.filter((jc) =>
                                            newValue.some((sector) =>
                                              jc.sectors.some((s) => s._id === sector._id)
                                            )
                                          )
                                        );

                                        setTradesList(
                                          trades.filter((jc) =>
                                            newValue.some((sector) =>
                                              jc.sectors.some((s) => s._id === sector._id)
                                            )
                                          )
                                        );
                                      } else {
                                        setTradesList(trades);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select sectors you want to apply to your ad"
                                        label="Sectors"
                                        variant="outlined"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Controller
                                name="jobCategories"
                                control={control}
                                defaultValue={[]}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    className="mt-8 mb-16"
                                    multiple
                                    options={jobCategoriesList || ['']}
                                    groupBy={(option) =>
                                      sectors.find((item) =>
                                        getValues('sectors').some((s) => s._id === item._id)
                                      ).name
                                    }
                                    getOptionLabel={(option) => option?.name || ''}
                                    value={value}
                                    onChange={(event, newValue) => {
                                      setJobTypesList([]);

                                      setValue('jobTypes', []);
                                      setValue('trades', []);
                                      onChange(newValue);

                                      if (newValue.length > 0) {
                                        setJobTypesList(
                                          jobTypes.filter((jt) =>
                                            newValue.some((jc) => jt.jobCategory?._id === jc._id)
                                          )
                                        );

                                        setTradesList(
                                          trades.filter((jt) =>
                                            newValue.some((jc) => jt.jobCategory?._id === jc._id)
                                          )
                                        );
                                      } else {
                                        setTradesList(trades);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select job categories you want to apply to your ad"
                                        label="Job Categories"
                                        variant="outlined"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Controller
                                name="jobTypes"
                                control={control}
                                defaultValue={[]}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    className="mt-8 mb-16"
                                    multiple
                                    options={jobTypesList || ['']}
                                    groupBy={(option) => option.jobCategory.name}
                                    getOptionLabel={(option) => option?.name || ''}
                                    value={value}
                                    onChange={(event, newValue) => {
                                      setValue('trades', []);

                                      onChange(newValue);

                                      if (newValue.length > 0) {
                                        setTradesList(
                                          trades.filter((trade) =>
                                            newValue.some((jt) => trade.jobType?._id === jt._id)
                                          )
                                        );
                                      } else {
                                        setTradesList(trades);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select job types you want to apply to your ad"
                                        label="Job Types"
                                        variant="outlined"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                name="search"
                                placeholder={tradesList.length > 0 ? tradesList[0].name : ''}
                                fullWidth
                                value={searchText}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Search color="action" />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={handleChange}
                              />
                            </Grid>

                            {_.filter(tradesList, (trade) => {
                              if (searchText.length > 0) {
                                return trade.name.toLowerCase().includes(searchText?.toLowerCase());
                              }

                              return true;
                            }).map((trade) => (
                              <Grid item xs={12} md={3}>
                                <TradeCard trade={trade} />
                              </Grid>
                            ))}
                          </Grid>

                          <div className={classes.actionsContainer}>
                            <div className="mt-20">
                              <Button
                                disabled={
                                  selectedTrades?.length === 0 || selectedTrades === undefined
                                }
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                                className={classes.button}
                              >
                                Next
                              </Button>
                            </div>
                            {/* <pre>{JSON.stringify(form, null, 2)}</pre> */}
                          </div>
                        </>
                      )}
                      {index === 1 && (
                        <>
                          <Grid container spacing={2}>
                            {attributes.map(({ name, options }) => {
                              return (
                                <Grid item xs={12} md={4}>
                                  <Controller
                                    name={`jobAttributes.${name}`}
                                    control={control}
                                    defaultValue={[]}
                                    render={({ field: { onChange, value } }) => (
                                      <Autocomplete
                                        className="mt-8 mb-16"
                                        options={options}
                                        value={value}
                                        onChange={(event, newValue) => {
                                          onChange(newValue);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder={`Select ${name}`}
                                            label={`${
                                              name.charAt(0).toUpperCase() + name.slice(1)
                                            }`}
                                            variant="outlined"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>

                          <div className={classes.actionsContainer}>
                            <div>
                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                              >
                                Back
                              </Button>
                              <Button
                                // disabled={!selectedSector?._id}
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                                className={classes.button}
                              >
                                Next
                              </Button>
                            </div>
                            {/* <pre>{JSON.stringify(form, null, 2)}</pre> */}
                          </div>
                        </>
                      )}
                      {index === 2 && (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <Controller
                                name="companyName"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    className="mt-8 mb-16"
                                    label="Company Name"
                                    id="companyName"
                                    name="companyName"
                                    placeholder="ABC Company"
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Controller
                                name="country"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    fullWidth
                                    freeSolo
                                    options={countries || ['']}
                                    getOptionLabel={(option) => option?.name || ''}
                                    value={value}
                                    onChange={(event, newValue) => {
                                      onChange(newValue);

                                      setStates(
                                        State.getStatesOfCountry(
                                          getValues('country')?.isoCode?.toUpperCase()
                                        )
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="mt-8 mb-16"
                                        label="Country"
                                        variant="outlined"
                                        fullWidth
                                        required
                                      />
                                    )}
                                  />
                                )}
                              />
                            </Grid>

                            {getValues('country')?.name?.length > 0 && states && (
                              <Grid item xs={12} md={6}>
                                <Controller
                                  name="state"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                      fullWidth
                                      freeSolo
                                      options={states || []}
                                      getOptionLabel={(option) => option?.name || ''}
                                      value={value}
                                      onChange={(event, newValue) => {
                                        onChange(newValue);
                                        setCities(
                                          City.getCitiesOfState(
                                            getValues('country')?.isoCode?.toUpperCase(),
                                            getValues('state')?.isoCode
                                          )
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="mt-8 mb-16"
                                          label="State/Province"
                                          variant="outlined"
                                          fullWidth
                                          required
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Grid>
                            )}

                            {getValues('state')?.name?.length > 0 && cities && (
                              <Grid item xs={12} md={6}>
                                <Controller
                                  name="city"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                      fullWidth
                                      freeSolo
                                      options={cities || ['']}
                                      getOptionLabel={(option) => option?.name || ''}
                                      getOptionSelected={(option, newValue) =>
                                        option._id === newValue._id
                                      }
                                      value={value}
                                      onChange={(event, newValue) => {
                                        onChange(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="mt-8 mb-16"
                                          label="Company Location"
                                          variant="outlined"
                                          fullWidth
                                          required
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Grid>
                            )}

                            <Grid item xs={12} md={6}>
                              <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    className="mt-8 mb-16"
                                    label="Job Post Title"
                                    id="name"
                                    name="name"
                                    variant="outlined"
                                    placeholder={
                                      selectedTrades?.length > 0
                                        ? selectedTrades[0]?.name
                                        : 'Fullstack Developer'
                                    }
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Controller
                                name="dueDate"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    type="date"
                                    className="mt-8 mb-16"
                                    label="Due Date"
                                    id="dueDate"
                                    variant="outlined"
                                    fullWidth
                                    autoFocus
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Controller
                                name="link"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    className="mt-8 mb-16"
                                    label="Application Link"
                                    id="link"
                                    name="link"
                                    placeholder="https://linkedin.com"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>

                          <div className={classes.actionsContainer}>
                            <div>
                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                              >
                                Back
                              </Button>
                              <Button
                                disabled={
                                  getValues('companyName') === undefined ||
                                  getValues('companyName').length === 0 ||
                                  getValues('name') === undefined ||
                                  getValues('name').length === 0 ||
                                  getValues('dueDate') === undefined ||
                                  getValues('dueDate').length === 0
                                }
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                                className={classes.button}
                              >
                                Next
                              </Button>
                            </div>
                            {/* <pre>{JSON.stringify(form, null, 2)}</pre> */}
                          </div>
                        </>
                      )}
                      {index === 3 && (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Controller
                                className="mt-8 mb-16"
                                render={({ field }) => <WYSIWYGEditor {...field} />}
                                name="message"
                                control={control}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body1" className="text-10 md:text-right">
                                Hint: You can copy and paste your Job Advert details from your word
                                document editor **
                              </Typography>
                            </Grid>
                          </Grid>

                          <div className={classes.actionsContainer}>
                            <div>
                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                              >
                                Back
                              </Button>
                              <Button
                                disabled={
                                  getValues('message') === undefined ||
                                  getValues('message')?.length === 0
                                }
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                                className={classes.button}
                              >
                                Next
                              </Button>
                            </div>
                            {/* <pre>{JSON.stringify(form, null, 2)}</pre> */}
                          </div>
                        </>
                      )}
                      {index === 4 && (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <ShowcaseCardDemo ad={getValues()} />
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="body1" className="text-10 md:text-right">
                              Hint: This is what your Ad will look like after being submited **
                            </Typography>
                          </Grid>

                          <div className={classes.actionsContainer}>
                            <div>
                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                              >
                                Back
                              </Button>
                              <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={_.isEmpty(dirtyFields) || !isValid}
                              >
                                Submit Ad
                              </Button>
                            </div>
                            {/* <pre>{JSON.stringify(getValues(), null, 2)}</pre> */}
                          </div>
                        </>
                      )}
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </DialogContent>

          <DialogActions className="justify-between px-8 py-16">
            <div className="px-16">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={_.isEmpty(dirtyFields) || !isValid}
              >
                Send
              </Button>
            </div>
            <IconButton onClick={() => handleDelete()}>
              <Icon>delete</Icon>
            </IconButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default withReducer('mailApp', reducer)(MailCompose);
