import { combineReducers } from "@reduxjs/toolkit";
import login from "./loginSlice";
import register from "./registerSlice";
import user from "./userSlice";
import countries from "./countriesSlice";
import businessTypes from "./businessTypesSlice";
import industries from "./industriesSlice";
import sectors from "./sectorsSlice";
import products from "./productsSlice";
import services from "./servicesSlice";
import trades from "./tradesSlice";
import reports from "./reportsSlice";
import users from "./usersSlice";
import ads from "./adsSlice";
import ad from "./adSlice";
import categories from "./categoriesSlice";
import jobAttributes from './jobAttributesSlice';
import jobCategories from './jobCategoriesSlice';
import jobTypes from "./jobTypesSlice";
import jobPosts from "./jobPostsSlice";
import jobClassifications from "./jobClassificationsSlice";

const authReducers = combineReducers({
  user,
  users,
  ad,
  ads,
  login,
  register,
  countries,
  businessTypes,
  industries,
  sectors,
  products,
  services,
  trades,
  reports,
  categories,
  jobAttributes,
  jobCategories,
  jobTypes,
  jobPosts,
  jobClassifications
});

export default authReducers;
