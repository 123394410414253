import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getTrades = createAsyncThunk('suggestions/trades/getTrades', async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/trades`, { params });
  const data = await response.data;

  return data;
});

export const removeTrades = createAsyncThunk(
  'suggestions/trades/removeTrades',
  async (tradeIds, { dispatch, getState }) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/trades/remove-trades`, {
      tradeIds,
    });

    return tradeIds;
  }
);

const tradesAdapter = createEntityAdapter({});

export const { selectAll: selectTrades, selectById: selectTradeById } =
  tradesAdapter.getSelectors((state) => state.suggestions.trades);

const tradesSlice = createSlice({
  name: 'suggestions/trades',
  initialState: tradesAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setTradesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getTrades.fulfilled]: tradesAdapter.setAll,
    [removeTrades.fulfilled]: (state, action) => tradesAdapter.removeMany(state, action.payload),
  },
});

export const { setTradesSearchText } = tradesSlice.actions;

export default tradesSlice.reducer;
