import { combineReducers } from '@reduxjs/toolkit';
import filters from './filtersSlice';
import folders from './foldersSlice';
import labels from './labelsSlice';
import mail from './mailSlice';
import mails from './mailsSlice';
import industries from './industriesSlice';
import sectors from './sectorsSlice';
import jobCategories from './jobCategoriesSlice';
import jobTypes from './jobTypesSlice';
import trades from './tradesSlice';

const reducer = combineReducers({
  mails,
  mail,
  folders,
  labels,
  filters,
  industries,
  sectors,
  jobCategories,
  jobTypes,
  trades,
});

export default reducer;
