import { authRoles } from 'app/auth';
import { lazy } from 'react';

const UserEditProfileAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: '/apps/profile',
      component: lazy(() => import(/* webpackChunkName: "Edit Profile" */ './profile/Profile')),
    },
  ],
};

export default UserEditProfileAppConfig;
