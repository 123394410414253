import Badge from "@material-ui/core/Badge";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { useSelector, useDispatch } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "./store";

import { selectNotifications } from "./store/dataSlice";
import { toggleNotificationPanel } from "./store/stateSlice";

function NotificationPanelToggleButton(props) {
  const notifications = useSelector(selectNotifications);
  const user = useSelector(({ auth }) => auth.user);

  const dispatch = useDispatch();

  return (
    <IconButton
      className="w-40 h-40"
      onClick={(ev) => dispatch(toggleNotificationPanel())}
    >
      <Badge
        color="primary"
        badgeContent={user.notifications?.length}
        invisible={user.notifications?.length === 0}
      >
        {props.children}
      </Badge>
    </IconButton>
  );
}

NotificationPanelToggleButton.defaultProps = {
  children: <Icon>notifications</Icon>,
};

export default withReducer(
  "notificationPanel",
  reducer
)(NotificationPanelToggleButton);
