import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import { Button, Input, MenuItem, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectIndustries } from 'app/auth/store/industriesSlice';
import History from '@history';
import { useEffect, useState } from 'react';
import {
  closeSearchSuggestions,
  openSearchSuggestions,
  setSearchText,
  setSelectedIndustry,
} from 'app/auth/store/userSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function NavSearch() {
  const classes = useStyles();
  const industries = useSelector(selectIndustries);
  const [industryId, setIndustryId] = useState('all');
  const dispatch = useDispatch();
  const searchText = useSelector(({ auth }) => auth.user.searchText);
  const selectedIndustry = useSelector(({ auth }) => auth.user.selectedIndustry);
  const searchSuggestions = useSelector(({ auth }) => auth.user.searchSuggestions);

  const country = useSelector(({ i18n }) => i18n.language);

  const handleChange = (e) => {
    dispatch(setSearchText(e));
    if (e.target.value?.length > 0 && !searchSuggestions) {
      dispatch(openSearchSuggestions());
    } else if (e.target.value?.length === 0 && searchSuggestions) {
      dispatch(closeSearchSuggestions());
    }
  };

  const handleSearch = () => {
    dispatch(closeSearchSuggestions());
    History.push(`/results/industries/${industryId}?s=${searchText}&iso2=${country}`);
  };

  useEffect(() => {
    if (selectedIndustry) setIndustryId(selectedIndustry);
  }, [selectedIndustry]);

  return (
    <Paper component="form" className={`${classes.root} sm:w-full`} elevation={0}>
      <TextField
        id="select"
        // eslint-disable-next-line no-restricted-globals
        value={industryId}
        select
        variant="outlined"
        size="small"
        SelectProps={{ classes: { select: 'py-14' } }}
        defaultValue="all"
        className="w-1/3"
      >
        <MenuItem
          onClick={() => {
            dispatch(setSelectedIndustry('all'));
            History.push(`/results/industries/all/vacancies?iso2=${country}`);
          }}
          value="all"
        >
          Everywhere
        </MenuItem>
        {industries.map((industry) => (
          <MenuItem
            onClick={() => {
              dispatch(setSelectedIndustry(industry._id));
              History.push(`/results/industries/${industry._id}/vacancies?iso2=${country}`);
            }}
            value={industry._id}
          >
            {industry.name}
          </MenuItem>
        ))}
      </TextField>
      <Input
        value={searchText}
        disableUnderline
        className={classes.input}
        placeholder="Search in Global Labour Market"
        inputProps={{ 'aria-label': 'Search in Global Labour Market' }}
        onChange={(e) => handleChange(e)}
      />
      <Button
        color="primary"
        variant="contained"
        style={{ padding: '10px', marginRight: '-5px' }}
        aria-label="search"
        onClick={() => handleSearch()}
      >
        <SearchIcon />
      </Button>
    </Paper>
  );
}
