import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getAd = createAsyncThunk('auth/ad/getAd', async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/job-posts/${params.adId}`);
  const data = await response.data;

  return data === undefined ? null : data;
});

export const removeAd = createAsyncThunk(
  'auth/ad/removeAd',
  async (val, { dispatch, getState }) => {
    const { id } = getState().auth.ad;
    await axios.post('/api/ads/remove-ad', { id });

    return id;
  }
);

export const saveAd = createAsyncThunk('auth/ad/saveAd', async (adData, { dispatch, getState }) => {
  const { ad } = getState().auth;

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/user-products/`, {
    ...ad,
    ...adData,
  });
  const data = await response.data;

  return data;
});

const adSlice = createSlice({
  name: 'auth/ad',
  initialState: null,
  reducers: {
    resetAd: () => null,
    newAd: {
      reducer: (state, action) => action.payload,
      prepare: (event) => ({
        payload: {
          name: '',
          handle: '',
          description: '',
          ads: [],
          industries: [],
          tags: [],
          images: [],
          priceTaxExcl: 0,
          priceTaxIncl: 0,
          taxRate: 0,
          comparedPrice: 0,
          quantity: 0,
          sku: '',
          width: '',
          height: '',
          depth: '',
          weight: '',
          extraShippingFee: 0,
          active: true,
        },
      }),
    },
  },
  extraReducers: {
    [getAd.fulfilled]: (state, action) => action.payload,
    [saveAd.fulfilled]: (state, action) => action.payload,
    [removeAd.fulfilled]: (state, action) => null,
  },
});

export const { newAd, resetAd } = adSlice.actions;

export default adSlice.reducer;
