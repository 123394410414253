import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from 'app/store/i18nSlice';
import { CardMedia, Hidden, Icon, InputAdornment, TextField } from '@material-ui/core';
import { ExpandMore, Search } from '@material-ui/icons';
import History from '@history';
import { Country } from 'country-state-city';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import countriesImage from './images/flags.svg';

function CountrySwitcher(props) {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const countries = Country.getAllCountries();
  const location = useLocation();

  const [businessParams, setBusinessParams] = useState('');
  const [sectorParams, setSectorParams] = useState('');
  const [categoryParams, setCategoryParams] = useState('');
  const [jobCategoryParams, setJobCategoryParams] = useState('');
  const [jobTypeParams, setJobTypeParams] = useState('');
  const [tradeParams, setTradeParams] = useState('');
  const [serviceParams, setServiceParams] = useState('');
  const [keyword, setKeyword] = useState('');
  const [activeJobIndex, setActiveJobIndex] = useState(0);
  const [iso2, setIso2] = useState('');

  const currentLanguageId = useSelector(({ i18n }) => i18n.language);
  const currentLanguage =
    currentLanguageId === 'ALL'
      ? { isoCode: 'ALL', name: 'All Countries' }
      : Country.getCountryByCode(currentLanguageId);

  const [menu, setMenu] = useState(null);
  const [searchText, setSearchText] = useState('');

  const langMenuClick = (event) => {
    setMenu(event.currentTarget);
  };

  const langMenuClose = () => {
    setMenu(null);
  };

  function handleLanguageChange(lng) {
    dispatch(changeLanguage(lng.isoCode));

    const searchItem = 'iso2';

    let search = `${searchItem}=${lng.isoCode}&`;

    if (businessParams) {
      search = search.concat(`business[]=${businessParams}&`);
    }

    if (sectorParams) {
      search = search.concat(`sectors[]=${sectorParams}&`);
    }

    if (jobCategoryParams) {
      search = search.concat(`jobCategories[]=${jobCategoryParams}&`);
    }

    if (jobTypeParams) {
      search = search.concat(`jobTypes[]=${jobTypeParams}&`);
    }

    if (tradeParams) {
      search = search.concat(`trades[]=${tradeParams}&`);
    }

    if (activeJobIndex) {
      search = search.concat(`jobIndex=${activeJobIndex}&`);
    }

    History.replace({
      search,
    });

    langMenuClose();
  }

  const handleChange = (e) => {
    setSearchText(e.target?.value);
  };

  useEffect(() => {
    if (iso2?.length > 0) {
      dispatch(changeLanguage(iso2));
    } else if (user.country?.length > 0) {
      dispatch(changeLanguage(user.country));
    }
  }, [dispatch, user.country, iso2]);

  /**
   * PARAMS CHANGES & INITIALISATION
   * */
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const businessTypes = queryParams.get('business[]');
    const sectorsParams = queryParams.get('sectors[]');
    const categoriesParams = queryParams.get('categories[]');
    const jobCategoriesParams = queryParams.get('jobCategories[]');
    const jobTypesParams = queryParams.get('jobTypes[]');
    const tradesParams = queryParams.get('trades[]');
    const servicesParams = queryParams.get('services[]');
    const searchWord = queryParams.get('s');
    const jobIndex = queryParams.get('jobIndex');
    const iso2Country = queryParams.get('iso2');

    if (businessTypes) setBusinessParams(businessTypes);
    else setBusinessParams('');
    if (sectorsParams) setSectorParams(sectorsParams);
    else setSectorParams('');
    if (categoriesParams) setCategoryParams(categoriesParams);
    else setCategoryParams('');
    if (jobCategoriesParams) setJobCategoryParams(jobCategoriesParams);
    else setJobCategoryParams('');
    if (jobTypesParams) setJobTypeParams(jobTypesParams);
    else setJobTypeParams('');
    if (tradesParams) setTradeParams(tradesParams);
    else setTradeParams('');
    if (servicesParams) setServiceParams(servicesParams);
    else setServiceParams('');
    if (searchWord) setKeyword(searchWord);
    else setKeyword('');
    if (jobIndex) setActiveJobIndex(jobIndex);
    else setActiveJobIndex(0);
    if (iso2Country) setIso2(iso2Country);
    else setIso2('');
  }, [location.search]);

  return (
    <>
      <Button
        startIcon={
          <Icon className="ml-2">
            {currentLanguage?.isoCode === 'ALL' ? (
              <CardMedia height={2} className="h-14 w-14" image={countriesImage} alt="flag" />
            ) : (
              <CardMedia className="h-14 w-14 absolute top-4">{currentLanguage?.flag}</CardMedia>
            )}
          </Icon>
        }
        endIcon={
          <Icon className="mr-2">
            <Typography color="textSecondary" className="absolute top-4">
              <ExpandMore color="inherit" />
            </Typography>
          </Icon>
        }
        className="ml-10 md:ml-0 md:w-full bg-grey-200"
        onClick={langMenuClick}
      >
        {/* <Hidden lgUp>
          <Typography className="mx-4 font-500 uppercase" color="textSecondary">
            {currentLanguage?.iso2}
          </Typography>
        </Hidden> */}
        <Hidden mdDown>
          <Typography className="mx-4 font-500 capitalize" color="textSecondary">
            {currentLanguage?.name}
          </Typography>
        </Hidden>
      </Button>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'pb-8',
        }}
      >
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          name="globalSearch"
          fullWidth
          value={searchText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={handleChange}
        />
        <MenuItem onClick={() => handleLanguageChange({ isoCode: 'ALL' })}>
          <ListItemIcon className="min-w-20">
            <span className="mx-4 min-w-20">
              <CardMedia height={200} className="h-16 w-16" image={countriesImage} alt="flag" />
            </span>
          </ListItemIcon>
          <ListItemText primary="All Countries" />
        </MenuItem>
        {countries
          .filter((country) =>
            searchText.length > 0
              ? country.name?.toLowerCase().includes(searchText?.toLocaleLowerCase())
              : true
          )
          .map((lng) => (
            <MenuItem key={lng.isoCode} onClick={() => handleLanguageChange(lng)}>
              <ListItemIcon className="min-w-20">
                <span className="mx-4 min-w-20">{Country.getCountryByCode(lng.isoCode)?.flag}</span>
              </ListItemIcon>
              <ListItemText primary={lng.name} />
            </MenuItem>
          ))}
      </Popover>
    </>
  );
}

export default CountrySwitcher;
