import FuseScrollbars from "@fuse/core/FuseScrollbars";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import withReducer from "app/store/withReducer";
import { useSnackbar } from "notistack";
import { useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import NotificationModel from "./model/NotificationModel";
import NotificationCard from "./NotificationCard";
import NotificationTemplate from "./NotificationTemplate";
import {
  addNotification,
  dismissAll,
  dismissItem,
  selectNotifications,
} from "./store/dataSlice";
import reducer from "./store";
import {
  closeNotificationPanel,
  toggleNotificationPanel,
} from "./store/stateSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: 320,
  },
}));

let displayed = [];

const storeDisplayed = (id) => {
  displayed = [...displayed, id];
};

const removeDisplayed = (id) => {
  displayed = [...displayed.filter((key) => id !== key)];
};

function NotificationPanel(props) {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector(({ notificationPanel }) => notificationPanel.state);
  const notifications = useSelector(selectNotifications);
  const user = useSelector(({ auth }) => auth.user);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    /*
		Add Notifications
		 */
    function createNotification(obj) {
      dispatch(addNotification(NotificationModel(obj)));
    }

    user.notifications
      ?.slice()
      .reverse()
      .map((item, index) =>
        setTimeout(
          () =>
            createNotification({
              id: item._id,
              message: item.message,
              options: item.options,
            }),
          (index + 5) * 1000
        )
      );
  }, [dispatch, user]);

  useEffect(() => {
    notifications.forEach((item) => {
      const { id: key, message, options = {}, dismissed = false } = item;

      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }
      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) {
        return;
      }

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        // autoHideDuration: 3000,
        content: () => (
          <NotificationTemplate
            item={item}
            onClose={() => {
              closeSnackbar(key);
              dispatch(dismissItem(key));
            }}
          />
        ),
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {},
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  useEffect(() => {
    if (state) {
      dispatch(closeNotificationPanel());
    }
    // eslint-disable-next-line
  }, [location, dispatch]);

  function handleClose() {
    dispatch(closeNotificationPanel());
  }

  function handleDismiss(id) {
    dispatch(dismissItem({ id, user }));
  }
  function handleDismissAll() {
    dispatch(dismissAll({ user }));

  }

  return (
    <SwipeableDrawer
      classes={{ paper: clsx(classes.root) }}
      open={state}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={(ev) => dispatch(toggleNotificationPanel())}
      disableSwipeToOpen
    >
      <IconButton
        className="m-4 absolute top-0 right-0 z-999"
        onClick={handleClose}
      >
        <Icon color="action">close</Icon>
      </IconButton>
      {notifications.length > 0 ? (
        <FuseScrollbars className="p-16">
          <div className="flex flex-col">
            <div className="flex justify-between items-end pt-136 mb-36">
              <Typography className="text-28 font-semibold leading-none">
                Notifications
              </Typography>
              <Typography
                className="text-12 underline cursor-pointer"
                color="secondary"
                onClick={handleDismissAll}
              >
                dismiss all
              </Typography>
            </div>
            {notifications.map((item) => (
              <NotificationCard
                key={item.id}
                className="mb-16"
                item={item}
                onClose={handleDismiss}
              />
            ))}
          </div>
        </FuseScrollbars>
      ) : (
        <div className="flex flex-1 items-center justify-center p-16">
          <Typography className="text-24 text-center" color="textSecondary">
            There are no notifications for now.
          </Typography>
        </div>
      )}
    </SwipeableDrawer>
  );
}

export default withReducer(
  "notificationPanel",
  reducer
)(memo(NotificationPanel));
