import History from '@history';
import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { motion } from 'framer-motion';

function PoweredByLinks() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, scale: 0.6 },
    show: { opacity: 1, scale: 1 },
  };

  return (
    <motion.div variants={container} initial="hidden" animate="show" className="flex items-center">

      <Tooltip
        variants={item}
        title="Learn more about Progressive Digital Technology and other services and platforms it offers"
        placement="top"
        className="m-20 ml-0"
      >
        <Button
          onClick={() => History.push('/learn-more')}
          color="secondary"
          variant="contained"
          startIcon={
            <img className="h-20" src="assets/images/logos/pdt.svg" alt="pdt" height="50" />
          }
        >
          Learn More
        </Button>
      </Tooltip>
    </motion.div>
  );
}

export default PoweredByLinks;
