import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ErrorBoundary } from 'react-error-boundary';
import { Auth } from './auth';
import withAppProviders from './withAppProviders';
import Error500Page from './main/pages/errors/500/Error500Page';

/**
 * Axios HTTP Request defaults
 */
axios.defaults.baseURL = '';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

const stripePromise = loadStripe(
  'pk_test_51OmUETJFCiHDKzPcgPuGkYwI3orMKWoj0VOIB0b5Vuq9mVvDj9GK6mijrfY7cFVhUI2CzcInQfpBVg9Qey3CkmzG002epBjzt6'
);

const App = () => {
  const ErrorFallback = ({ error }) => {
    // We can customize the UI as we want
    return <Error500Page error={error} />;
  };

  const logError = (error) => {
    // setErrorMessage(error.message);
    console.error(error);
    // We can also send the error to a logging service
  };

  const handleResetError = () => {
    console.log('Error boundary reset');
    // setErrorMessage("");
    // Additional logic to perform code cleanup and state update actions
  };

  return (
    <ErrorBoundary onError={logError} onReset={handleResetError} FallbackComponent={ErrorFallback}>
      <Elements stripe={stripePromise}>
        <Auth>
          <Router history={history}>
            <FuseAuthorization>
              <FuseTheme>
                <SnackbarProvider
                  maxSnack={5}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  classes={{
                    containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                  }}
                >
                  <FuseLayout />
                </SnackbarProvider>
              </FuseTheme>
            </FuseAuthorization>
          </Router>
        </Auth>
      </Elements>
    </ErrorBoundary>
  );
};

export default withAppProviders(App)();
