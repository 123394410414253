import { authRoles } from 'app/auth';
import { lazy } from 'react';

const SupplierProfilePageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/profile",
      component: lazy(() => import(/* webpackChunkName: "Logged User" */ "./ProfilePage")),
      exact: true,
    },
  ],
};

export default SupplierProfilePageConfig;
