import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getUsers = createAsyncThunk('auth/users/getUsers', async (params, { dispatch }) => {
  dispatch(setLoading(true));
  const response = await axios
    .get(`${process.env.REACT_APP_API_URL}/v1/users`, { params })
    .then((res) => {
      dispatch(setLoading(false));
      return res;
    });
  const data = await response.data;

  return data;
});

export const removeUsers = createAsyncThunk(
  'auth/users/removeUsers',
  async (serviceIds, { dispatch, getState }) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/users/remove-users`, {
      serviceIds,
    });

    return serviceIds;
  }
);

const usersAdapter = createEntityAdapter({});

export const { selectAll: selectUsers, selectById: selectServiceById } = usersAdapter.getSelectors(
  (state) => state.auth.users
);

const usersSlice = createSlice({
  name: 'auth/users',
  initialState: usersAdapter.getInitialState({
    searchText: '',
    loading: true,
    jobSeekerCount: 0,
    employerCount: 0,
    supplierCount: 0,
    recruiterCount: 0,
  }),
  reducers: {
    setUsersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setJobSeekerCount: {
      reducer: (state, action) => {
        state.jobSeekerCount = action.payload;
      },
    },
    setEmployerCount: {
      reducer: (state, action) => {
        state.employerCount = action.payload;
      },
    },
    setSupplierCount: {
      reducer: (state, action) => {
        state.supplierCount = action.payload;
      },
    },
    setRecruiterCount: {
      reducer: (state, action) => {
        state.recruiterCount = action.payload;
      },
    },
    setLoading: {
      reducer: (state, action) => {
        state.loading = action.payload;
      },
    },
  },
  extraReducers: {
    [getUsers.fulfilled]: usersAdapter.setAll,
    [removeUsers.fulfilled]: (state, action) => usersAdapter.removeMany(state, action.payload),
  },
});

export const {
  setUsersSearchText,
  setRecruiterCount,
  setSupplierCount,
  setEmployerCount,
  setJobSeekerCount,
  setLoading,
} = usersSlice.actions;

export default usersSlice.reducer;
