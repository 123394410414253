import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCountries = createAsyncThunk('auth/countries/getCountries', async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/countries`
  );
  const data = await response.data;

  return data;
});

export const removeCountries = createAsyncThunk(
  'auth/countries/removeCountries',
  async (countryIds, { dispatch, getState }) => {
    await axios.post('/api/countries/remove-countries', { countryIds });

    return countryIds;
  }
);

const countriesAdapter = createEntityAdapter({});

export const { selectAll: selectCountries, selectById: selectCountryById } =
  countriesAdapter.getSelectors((state) => state.auth.countries);

const countriesSlice = createSlice({
  name: 'auth/countries',
  initialState: countriesAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setCountriesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getCountries.fulfilled]: countriesAdapter.setAll,
    [removeCountries.fulfilled]: (state, action) =>
      countriesAdapter.removeMany(state, action.payload),
  },
});

export const { setCountriesSearchText } = countriesSlice.actions;

export default countriesSlice.reducer;
