/* eslint import/no-extraneous-dependencies: off */
import { createSlice } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings, setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import jwtService from 'app/services/jwtService';

export const setUserData = (user) => async (dispatch, getState) => {
  // user.shortcuts = ['suppliers', 'employers', 'recruiters', 'job-seekers', 'hire-with-ldm'];
  user.shortcuts = [
    // "suppliers",
    // "employers",
    // "recruiters",
    // "job-seekers",
    // "hire-with-ldm",
  ];

  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get('redirect');

  /*
    You can redirect the logged-in user to a specific route depending on his role
   */
  user.redirectUrl = redirectUrl;
  history.location.state = {
    redirectUrl: user.redirectUrl, // for example 'apps/academy'
  };

  /*
    Set User Settings
  */
  dispatch(setDefaultSettings({ ...user.settings }));

  dispatch(setUser(user));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, { settings });

  dispatch(updateUserData(user));

  return dispatch(setUserData(user));
};

export const updateUserShortcuts = (shortcuts) => async (dispatch, getState) => {
  const { user } = getState().auth;
  const newUser = {
    ...user,
    shortcuts,
  };

  dispatch(updateUserData(user));

  return dispatch(setUserData(newUser));
};

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  history.push({
    pathname: window.location.pathname,
  });

  switch (user.from) {
    default: {
      jwtService.logout();
    }
  }

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }
  switch (user.from) {
    default: {
      jwtService
        .updateUserData(user)
        .then(() => {
          dispatch(showMessage({ message: 'User data saved with api' }));
        })
        .catch((error) => {
          dispatch(showMessage({ message: error.message }));
        });
      break;
    }
  }
};

const initialState = {
  role: [], // guest
  trades: [],
  data: {
    displayName: 'John Doe',
    photoURL: 'assets/images/avatars/Velazquez.jpg',
    email: 'johndoe@withinpixels.com',
    shortcuts: ['calendar', 'mail', 'contacts', 'todo'],
  },
  searchText: '',
  selectedIndustry: 'all',
  searchSuggestions: false,
  chatAlertDialog: {
    props: {
      open: false,
    },
  },
  loginAlertDialog: {
    props: {
      open: false,
    },
  },
  jobInfoDialog: {
    props: {
      open: false,
      data: {},
    },
  },
  shareAlertDialog: {
    props: {
      open: false,
      data: {},
    },
  },
  contactDetailsAlertDialog: {
    props: {
      open: false,
      data: {},
    },
  },
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => ({ ...initialState, ...action.payload }),
    userLoggedOut: (state, action) => initialState,
    setSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setSelectedIndustry: {
      reducer: (state, action) => {
        state.selectedIndustry = action.payload;
      },
      prepare: (event) => ({ payload: event || '' }),
    },
    openSearchSuggestions: (state, action) => {
      state.searchSuggestions = true;
    },
    closeSearchSuggestions: (state, action) => {
      state.searchSuggestions = false;
    },
    openChatAlertDialog: (state, action) => {
      state.chatAlertDialog = {
        props: {
          open: true,
        },
      };
    },
    closeChatAlertDialog: (state, action) => {
      state.chatAlertDialog = {
        props: {
          open: false,
        },
      };
    },
    openLoginAlertDialog: (state, action) => {
      state.loginAlertDialog = {
        props: {
          open: true,
        },
      };
    },
    closeLoginAlertDialog: (state, action) => {
      state.loginAlertDialog = {
        props: {
          open: false,
        },
      };
    },
    openJobInfoDialog: (state, action) => {
      state.jobInfoDialog = {
        props: {
          open: true,
          data: action.payload || {},
        },
      };
    },
    closeJobInfoDialog: (state, action) => {
      state.jobInfoDialog = {
        props: {
          open: false,
          data: {},
        },
      };
    },
    openShareAlertDialog: (state, action) => {
      state.shareAlertDialog = {
        props: {
          open: true,
          data: action.payload || '',
        },
      };
    },
    closeShareAlertDialog: (state, action) => {
      state.shareAlertDialog = {
        props: {
          open: false,
          data: '',
        },
      };
    },
    openContactDetailsAlertDialog: (state, action) => {
      state.contactDetailsAlertDialog = {
        props: {
          open: true,
          data: action.payload,
        },
      };
    },
    closeContactDetailsAlertDialog: (state, action) => {
      state.contactDetailsAlertDialog = {
        props: {
          open: false,
          data: {},
        },
      };
    },
  },
  extraReducers: {},
});

export const {
  setUser,
  userLoggedOut,
  setSearchText,
  setSelectedIndustry,
  openSearchSuggestions,
  closeSearchSuggestions,
  openLoginAlertDialog,
  closeLoginAlertDialog,
  openShareAlertDialog,
  closeShareAlertDialog,
  openContactDetailsAlertDialog,
  closeContactDetailsAlertDialog,
  openChatAlertDialog,
  closeChatAlertDialog,
  openJobInfoDialog,
  closeJobInfoDialog,
} = userSlice.actions;

export default userSlice.reducer;
