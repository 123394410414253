import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getProducts = createAsyncThunk('suggestions/products/getProducts', async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/products`, { params });
  const data = await response.data;

  return data;
});

export const removeProducts = createAsyncThunk(
  'suggestions/products/removeProducts',
  async (productIds, { dispatch, getState }) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/products/remove-products`, {
      productIds,
    });

    return productIds;
  }
);

const productsAdapter = createEntityAdapter({});

export const { selectAll: selectProducts, selectById: selectProductById } =
  productsAdapter.getSelectors((state) => state.suggestions.products);

const productsSlice = createSlice({
  name: 'suggestions/products',
  initialState: productsAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setProductsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getProducts.fulfilled]: productsAdapter.setAll,
    [removeProducts.fulfilled]: (state, action) => productsAdapter.removeMany(state, action.payload),
  },
});

export const { setProductsSearchText } = productsSlice.actions;

export default productsSlice.reducer;
