import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getIndustries = createAsyncThunk('suggestions/industries/getIndustries', async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/industries`
  );
  const data = await response.data;

  return data;
});

export const removeIndustries = createAsyncThunk(
  'suggestions/industries/removeIndustries',
  async (industryIds, { dispatch, getState }) => {
    await axios.post('/api/industries/remove-industries', { industryIds });

    return industryIds;
  }
);

const industriesAdapter = createEntityAdapter({});

export const { selectAll: selectIndustries, selectById: selectIndustryById } =
  industriesAdapter.getSelectors((state) => state.suggestions.industries);

const industriesSlice = createSlice({
  name: 'suggestions/industries',
  initialState: industriesAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setIndustriesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getIndustries.fulfilled]: industriesAdapter.setAll,
    [removeIndustries.fulfilled]: (state, action) =>
      industriesAdapter.removeMany(state, action.payload),
  },
});

export const { setIndustriesSearchText } = industriesSlice.actions;

export default industriesSlice.reducer;
