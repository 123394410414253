import ForgotPasswordPageConfig from "./auth/forgot-password/ForgotPasswordPageConfig";
import LockPageConfig from "./auth/lock/LockPageConfig";
import LoginPageConfig from "./auth/login/LoginPageConfig";
import MailConfirmPageConfig from "./auth/mail-confirm/MailConfirmPageConfig";
import RegisterPageConfig from "./auth/register/RegisterPageConfig";
import ResetPasswordPageConfig from "./auth/reset-password/ResetPasswordPageConfig";
import ComingSoonPageConfig from "./coming-soon/ComingSoonPageConfig";
import Error404PageConfig from "./errors/404/Error404PageConfig";
import Error500PageConfig from "./errors/500/Error500PageConfig";
import FaqPageConfig from "./faq/FaqPageConfig";
import KnowledgeBasePageConfig from "./knowledge-base/KnowledgeBaseConfig";
import MaintenancePageConfig from "./maintenance/MaintenancePageConfig";
import PricingStyle1PageConfig from "./pricing/PricingStyle1PageConfig";
import ProfilePageConfig from "./profile/ProfilePageConfig";
import SupplierProfilePageConfig from "./logged-user-profile/SupplierProfilePageConfig";
import WebAppPagesConfig from "./web-app/WebAppPagesConfig";
import TermsAndConditionsPageConfig from "./policies-and-info/TermsAndConditions/TermsAndConditionsPageConfig";
import PrivacyPolicyPageConfig from "./policies-and-info/PrivacyPolicy/PrivacyPolicyPageConfig";
import LearnMorePageConfig from "./learn-more/LearnMorePageConfig";
import SubscriptionsPageConfig from "./subscriptions/SubscriptionsPageConfig";

const pagesConfigs = [
  WebAppPagesConfig,
  LoginPageConfig,
  RegisterPageConfig,
  ResetPasswordPageConfig,
  ForgotPasswordPageConfig,
  MailConfirmPageConfig,
  LockPageConfig,
  ComingSoonPageConfig,
  Error404PageConfig,
  Error500PageConfig,
  MaintenancePageConfig,
  PricingStyle1PageConfig,
  ProfilePageConfig,
  FaqPageConfig,
  KnowledgeBasePageConfig,
  SupplierProfilePageConfig,
  TermsAndConditionsPageConfig,
  PrivacyPolicyPageConfig,
  LearnMorePageConfig,
  SubscriptionsPageConfig
];

export default pagesConfigs;
