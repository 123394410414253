import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getJobCategories = createAsyncThunk(
  "auth/jobCategories/getJobCategories",
  async (params, { dispatch }) => {
     dispatch(setLoading(true));
     const response = await axios
       .get(`${process.env.REACT_APP_API_URL}/v1/job-categories`, { params })
       .then((res) => {
         dispatch(setLoading(false));
         return res;
       });
    const data = await response.data;

    return data;
  }
);

export const removeJobCategories = createAsyncThunk(
  "auth/jobCategories/removeJobCategories",
  async (jobCategoryIds, { dispatch, getState }) => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/jobCategories/remove-jobCategories`,
      {
        jobCategoryIds,
      }
    );

    return jobCategoryIds;
  }
);

const jobCategoriesAdapter = createEntityAdapter({});

export const {
  selectAll: selectJobCategories,
  selectById: selectJobCategoryById,
} = jobCategoriesAdapter.getSelectors((state) => state.auth.jobCategories);

const jobCategoriesSlice = createSlice({
  name: "auth/jobCategories",
  initialState: jobCategoriesAdapter.getInitialState({
    searchText: "",
    loading: true,
  }),
  reducers: {
    setJobCategoriesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    setLoading: {
      reducer: (state, action) => {
        state.loading = action.payload;
      },
    },
  },
  extraReducers: {
    [getJobCategories.fulfilled]: jobCategoriesAdapter.setAll,
    [removeJobCategories.fulfilled]: (state, action) =>
      jobCategoriesAdapter.removeMany(state, action.payload),
  },
});

export const { setJobCategoriesSearchText, setLoading } = jobCategoriesSlice.actions;

export default jobCategoriesSlice.reducer;
