import { lazy } from 'react';

const ComingSoonPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/pages/coming-soon',
      component: lazy(() => import(/* webpackChunkName: "Coming Soon" */ './ComingSoonPage')),
    },
  ],
};

export default ComingSoonPageConfig;
