import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCategories = createAsyncThunk('suggestions/categories/getCategories', async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/categories`, { params });
  const data = await response.data;

  return data;
});

export const removeCategories = createAsyncThunk(
  'suggestions/categories/removeCategories',
  async (categoryIds, { dispatch, getState }) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/categories/remove-categories`, {
      categoryIds,
    });

    return categoryIds;
  }
);

const categoriesAdapter = createEntityAdapter({});

export const { selectAll: selectCategories, selectById: selectCategoryById } =
  categoriesAdapter.getSelectors((state) => state.suggestions.categories);

const categoriesSlice = createSlice({
  name: 'suggestions/categories',
  initialState: categoriesAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setCategoriesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getCategories.fulfilled]: categoriesAdapter.setAll,
    [removeCategories.fulfilled]: (state, action) => categoriesAdapter.removeMany(state, action.payload),
  },
});

export const { setCategoriesSearchText } = categoriesSlice.actions;

export default categoriesSlice.reducer;
