/**
 * Authorization Roles
 */
const authRoles = {
  anyOne: [
    "ldm-agent",
    "ldm-job-seeker",
    "ldm-recruiter",
    "ldm-employer",
    "ldm-labour-supplier",
  ],
  user: [
    "pdm-user",
    "ldm-job-seeker",
    "ldm-recruiter",
    "ldm-employer",
    "ldm-labour-supplier",
    "ldm-admin",
    "pdt-user"
  ],
  recruiterAndEmployer: ["ldm-recruiter", "ldm-employer"],
  recruiterAndSupplier: ["ldm-recruiter", "ldm-labour-supplier"],
  jobSeekerAndEmployer: ["ldm-job-seeker", "ldm-employer"],
  supplierAndEmployer: ["ldm-labour-supplier", "ldm-employer"],
  jobSeeker: ["ldm-job-seeker"],
  employer: ["ldm-employer"],
  recruiter: ["ldm-recruiter"],
  supplier: ["ldm-labour-supplier"],
  recruiterAndEmployerAndSupplier: [
    "ldm-recruiter",
    "ldm-employer",
    "ldm-labour-supplier",
  ],
  onlyGuest: [],
};

export default authRoles;
