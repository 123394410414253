import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { closeChatAlertDialog } from 'app/auth/store/userSlice';

export default function ChatAlert() {
  const [open, setOpen] = React.useState(false);
  const dialog = useSelector(({ auth }) => auth.user.chatAlertDialog);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    dispatch(closeChatAlertDialog());
  }

  return (
    <Dialog
      {...dialog?.props}
      onClose={closeComposeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: "m-24 rounded-8",
      }}
    >
      <DialogTitle id="alert-dialog-title">
        Chat request awaiting approval
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          User has not accepted your request to chat yet. A notification has
          been sent to remind them of your request. You will be notified as soon
          as they accept your request.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
