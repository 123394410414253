import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getJobAttributes = createAsyncThunk(
  "auth/jobAttributes/getJobAttributes",
  async (params) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/job-attributes`,
      { params }
    );
    const data = await response.data;

    return data;
  }
);

export const removeJobAttributes = createAsyncThunk(
  "auth/jobAttributes/removeJobAttributes",
  async (jobAttributeIds, { dispatch, getState }) => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/jobAttributes/remove-job-attributes`,
      {
        jobAttributeIds,
      }
    );

    return jobAttributeIds;
  }
);

const jobAttributesAdapter = createEntityAdapter({});

export const { selectAll: selectJobAttributes, selectById: selectJobAttributeById } =
  jobAttributesAdapter.getSelectors((state) => state.auth.jobAttributes);

const jobAttributesSlice = createSlice({
  name: "auth/jobAttributes",
  initialState: jobAttributesAdapter.getInitialState({
    searchText: "",
  }),
  reducers: {
    setJobAttributesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
  },
  extraReducers: {
    [getJobAttributes.fulfilled]: jobAttributesAdapter.setAll,
    [removeJobAttributes.fulfilled]: (state, action) =>
      jobAttributesAdapter.removeMany(state, action.payload),
  },
});

export const { setJobAttributesSearchText } = jobAttributesSlice.actions;

export default jobAttributesSlice.reducer;
