import History from '@history';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { CloseOutlined, Search } from '@material-ui/icons';
import { closeSearchSuggestions, setSelectedIndustry } from 'app/auth/store/userSlice';
import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import reducer from './store';
import { getBusinessTypes } from './store/businessTypesSlice';
import { getJobCategories, selectJobCategories } from './store/jobCategoriesSlice';
import { getIndustries, selectIndustries } from './store/industriesSlice';
import { getTrades, selectTrades } from './store/tradesSlice';
import { getSectors, selectSectors } from './store/sectorsSlice';
import { getJobTypes, selectJobTypes } from './store/jobTypesSlice';

const createBreadCrumb = ({
  itemIndustries,
  itemSectors,
  itemJobCategories,
  itemJobClassifications,
  itemJobTypes,
  item,
}) => {
  let breadcrumb = '';

  if (itemIndustries?.length > 0) {
    breadcrumb = breadcrumb.concat(itemIndustries?.map((s) => s.name));

    if (
      itemSectors?.length > 0 ||
      itemJobCategories?.length > 0 ||
      itemJobTypes?.length > 0 ||
      item
    ) {
      breadcrumb = breadcrumb.concat(' - ');
    }
  }

  // if (itemSectors?.length > 0) {
  //   breadcrumb = breadcrumb.concat(itemSectors?.map((s) => s.name));

  //   if (
  //     itemJobClassifications?.length > 0 ||
  //     itemJobCategories?.length > 0 ||
  //     itemJobTypes?.length > 0 ||
  //     item
  //   ) {
  //     breadcrumb = breadcrumb.concat(' - ');
  //   }
  // }

  if (itemJobClassifications?.length > 0) {
    breadcrumb = breadcrumb.concat(itemJobClassifications?.map((s) => s.name));
    if (itemJobCategories?.length > 0 || item) {
      breadcrumb = breadcrumb.concat(' - ');
    }
  }

  if (itemJobCategories?.length > 0) {
    breadcrumb = breadcrumb.concat(itemJobCategories?.map((s) => s.name));
    if (itemJobTypes?.length > 0 || item) {
      breadcrumb = breadcrumb.concat(' - ');
    }
  }

  if (itemJobTypes?.length > 0) {
    breadcrumb = breadcrumb.concat(itemJobTypes?.map((s) => s.name));
    if (item) {
      breadcrumb = breadcrumb.concat(' - ');
    }
  }

  if (item) {
    breadcrumb = breadcrumb.concat(item.name);
  }

  return breadcrumb;
};

const createPath = ({
  itemIndustries,
  itemSectors,
  itemJobClassifications,
  itemJobCategories,
  itemJobTypes,
  item,
  business,
  suffix,
}) => {
  let search = '';

  if (itemSectors?.length > 0) {
    search = search.concat(`sectors[]=${itemSectors?.map((s) => s._id)}&`);
  }

  if (itemJobCategories?.length > 0) {
    search = search.concat(`jobCategories[]=${itemJobCategories?.map((s) => s._id)}&`);
  }

  if (itemJobClassifications?.length > 0) {
    search = search.concat(`business[]=${itemJobClassifications?.map((s) => s._id)}&`);
  }

  if (itemJobTypes?.length > 0) {
    search = search.concat(`jobTypes[]=${itemJobTypes?.map((s) => s._id)}&`);
  }

  if (item) {
    search = search.concat(`trades[]=${item._id}`);
  }

  return `/results/industries/${
    itemIndustries?.length > 0 ? itemIndustries[0]._id : 'all'
  }?${search}`;
};

const SearchAutocompleteMenu = () => {
  const dispatch = useDispatch();
  const searchText = useSelector(({ auth }) => auth.user.searchText);
  const selectedIndustry = useSelector(({ auth }) => auth.user.selectedIndustry);
  const searchSuggestions = useSelector(({ auth }) => auth.user.searchSuggestions);

  const country = useSelector(({ i18n }) => i18n.language);

  const industries = useSelector(selectIndustries);
  const sectors = useSelector(selectSectors);
  const jobCategories = useSelector(selectJobCategories);
  const trades = useSelector(selectTrades);
  const jobTypes = useSelector(selectJobTypes);

  const [data, setData] = useState([
    { name: 'Job Titles', data: [] },
    { name: 'Job Functions', data: [] },
    { name: 'Job Categories', data: [] },
    { name: 'Sectors', data: [] },
    { name: 'Business Types', data: [] },
    { name: 'Industries', data: [] },
  ]);

  const [suggestionsData, setSuggestionsData] = useState([]);

  useEffect(() => {
    dispatch(getIndustries());
    dispatch(getBusinessTypes());
    dispatch(getSectors());
    dispatch(getJobCategories());
    dispatch(getTrades());
    dispatch(getJobTypes());
  }, [dispatch]);

  useEffect(() => {
    const tradesArr = [];
    trades?.forEach((i) => {
      tradesArr.push({
        title: i.name,
        description: 'Trade Adverts',
        industries: i.industries,
      });
    });

    setSuggestionsData([...suggestionsData, ...tradesArr]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trades]);

  useEffect(() => {
    const tradesArr = [];
    jobTypes?.forEach((i) => {
      tradesArr.push({
        title: i.name,
        description: 'Companies',
        industries: i.industries,
      });
    });

    setSuggestionsData([...suggestionsData, ...tradesArr]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTypes]);

  // FORMAT TRADES SEARCH RESULTS
  useEffect(() => {
    let dataArr = [];

    const tradesArr = [];
    trades
      ?.filter((_t) => _t.industries?.length > 0)
      .forEach((i) => {
        tradesArr.push({
          title: i.name,
          industries: i.industries,
          description: createBreadCrumb({
            itemIndustries: [i.industries],
            itemSectors: i.sectors,
            itemJobCategories: i.jobCategory ? [i.jobCategory] : [],
            itemJobClassifications: i.jobCategory?.jobClassifications
              ? i.jobCategory?.jobClassifications
              : [],
            itemJobTypes: i.jobType ? [i.jobType] : [],
            item: i,
          }),
          path: createPath({
            itemIndustries: [i.industries],
            business: i.businessTypes,
            itemSectors: [],
            itemJobCategories: i.jobCategory ? [i.jobCategory] : [],
            itemJobClassifications: i.jobCategory?.jobClassifications
              ? i.jobCategory?.jobClassifications
              : [],
            itemJobTypes: i.jobType ? [i.jobType] : [],
            item: i,
          }),
          paths: i.industries.map((_item) => {
            return {
              industries: [_item],
              description: createBreadCrumb({
                itemIndustries: [_item],
                itemSectors: [],
                itemJobCategories: i.jobCategory ? [i.jobCategory] : [],
                itemJobClassifications: i.jobCategory?.jobClassifications
                  ? i.jobCategory?.jobClassifications
                  : [],
                itemJobTypes: i.jobType ? [i.jobType] : [],
                item: i,
              }),
              path: createPath({
                itemIndustries: [_item],
                business: i.businessTypes,
                itemSectors: [],
                itemJobCategories: i.jobCategory ? [i.jobCategory] : [],
                itemJobClassifications: i.jobCategory?.jobClassifications
                  ? i.jobCategory?.jobClassifications
                  : [],
                itemJobTypes: i.jobType ? [i.jobType] : [],
                item: i,
              }),
            };
          }),
        });
      });

    dataArr = data.map((d) => {
      if (d.name === 'Job Titles') {
        return { name: 'Job Titles', data: tradesArr };
      }

      return d;
    });

    setData(dataArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trades]);

  // FORMAT JOB TYPES SEARCH RESULTS
  useEffect(() => {
    let dataArr = [];

    const jobTypesArr = [];
    jobTypes
      ?.filter((_t) => _t.industries?.length > 0)
      .map((i) =>
        jobTypesArr.push({
          id: i._id,
          title: i.name,
          industries: i.industries,
          description: createBreadCrumb({
            itemIndustries: i.industries,
            itemSectors: [],
            itemJobCategories: i.jobCategory ? [i.jobCategory] : [],
            itemJobClassifications: i.jobCategory?.jobClassifications
              ? i.jobCategory?.jobClassifications
              : [],
            item: i,
          }),
          path: createPath({
            itemIndustries: i.industries,
            business: i.businessTypes,
            itemSectors: [],
            itemJobCategories: i.jobCategory ? [i.jobCategory] : [],
            itemJobClassifications: i.jobCategory?.jobClassifications
              ? i.jobCategory?.jobClassifications
              : [],
            itemJobTypes: [i],
          }),
          paths: i.industries.map((_item) => {
            return {
              industries: [_item],
              description: createBreadCrumb({
                itemIndustries: [_item],
                itemSectors: [],
                itemJobCategories: i.jobCategory ? [i.jobCategory] : [],
                itemJobClassifications: i.jobCategory?.jobClassifications
                  ? i.jobCategory?.jobClassifications
                  : [],
                item: i,
              }),
              path: createPath({
                itemIndustries: [_item],
                business: i.businessTypes,
                itemSectors: [],
                itemJobCategories: i.jobCategory ? [i.jobCategory] : [],
                itemJobClassifications: i.jobCategory?.jobClassifications
                  ? i.jobCategory?.jobClassifications
                  : [],
                itemJobTypes: [i],
              }),
            };
          }),
        })
      );

    dataArr = data.map((d) => {
      if (d.name === 'Job Functions') {
        return { name: 'Job Functions', data: jobTypesArr };
      }

      return d;
    });

    setData(dataArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTypes]);

  // FORMAT JOB CATEGORIES SEARCH RESULTS
  useEffect(() => {
    let dataArr = [];

    const jobCategoriesArr = [];
    jobCategories
      ?.filter((_t) => _t.industries?.length > 0)
      .map((i) =>
        jobCategoriesArr.push({
          id: i._id,
          title: i.name,
          industries: i.industries,
          description: createBreadCrumb({
            itemIndustries: i.industries,
            itemSectors: [],
            item: i,
            itemJobClassifications: i.jobClassifications ? i.jobClassifications : [],
          }),
          path: createPath({
            itemIndustries: i.industries,
            business: i.businessTypes,
            itemSectors: [],
            itemJobCategories: [i],
            itemJobClassifications: i.jobClassifications ? i.jobClassifications : [],
          }),
          paths: i.industries.map((_item) => {
            return {
              industries: [_item],
              description: createBreadCrumb({
                itemIndustries: [_item],
                itemSectors: [],
                item: i,
                itemJobClassifications: i.jobClassifications ? i.jobClassifications : [],
              }),
              path: createPath({
                itemIndustries: [_item],
                business: i.businessTypes,
                itemSectors: [],
                itemJobCategories: [i],
                itemJobClassifications: i.jobClassifications ? i.jobClassifications : [],
              }),
            };
          }),
        })
      );

    dataArr = data.map((d) => {
      if (d.name === 'Job Categories') {
        return { name: 'Job Categories', data: jobCategoriesArr };
      }

      return d;
    });

    setData(dataArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobCategories]);

  // FORMAT SECTORS SEARCH RESULTS
  useEffect(() => {
    let dataArr = [];

    const sectorsArr = [];
    sectors
      ?.filter((_t) => _t.industry?.name?.length > 0)
      .map((i) =>
        sectorsArr.push({
          id: i._id,
          title: i.name,
          industries: [i.industry],
          description: createBreadCrumb({
            itemIndustries: [i.industry],
            item: i,
          }),
          path: createPath({
            itemIndustries: [i.industry],
            business: i.businessTypes,
            itemSectors: [i],
          }),
        })
      );

    dataArr = data.map((d) => {
      if (d.name === 'Sectors') {
        return { name: 'Sectors', data: sectorsArr };
      }

      return d;
    });

    setData(dataArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectors]);

  // FORMAT INDUSTRIES SEARCH RESULTS
  useEffect(() => {
    let dataArr = [];

    const industryArr = [];

    industries?.map((i) =>
      industryArr.push({
        id: i._id,
        title: i.name,
        industries: [i],
        description: createBreadCrumb({
          itemIndustries: [i],
        }),
        path: createPath({
          itemIndustries: [i],
          business: i.businessTypes,
        }),
      })
    );

    dataArr = data.map((d) => {
      if (d.name === 'Industries') {
        return { name: 'Industries', data: industryArr };
      }

      return d;
    });

    setData(dataArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industries, selectedIndustry]);

  if (!searchSuggestions) {
    return null;
  }
  return (
    <div
      className={`w-full md:w-8/12 rounded-4 bg-white absolute ${
        isDesktop ? 'top-60' : 'top-128 -mt-10'
      } z-9999 left-0 md:left-208`}
    >
      <CloseOutlined
        className="absolute z-50 right-3 top-3 font-extrabold text-xl text-primary cursor-pointer"
        color="red"
        onClick={() => dispatch(closeSearchSuggestions())}
      />
      <Card className="flex-row w-full rounded-lg z-40 mt-8" elecation={0}>
        <Grid container>
          <Grid
            item
            md={4}
            style={{
              background: 'linear-gradient(to right, #0000FF 0%, rgba(0, 0, 255, 0.7) 100%)',
            }}
            className="hidden md:flex"
          >
            <CardMedia
              variant="gradient"
              className="h-full w-full place-items-center rounded-md m-16"
            >
              <img src="assets/images/logos/pdt.svg" alt="icon-logo" />
            </CardMedia>
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          </Grid>
          <Grid item md={8}>
            <CardContent className="h-4/5 md:h-400 overflow-x-hidden">
              {data.map((d) => {
                return (
                  <List
                    key={d.name}
                    dense
                    disablePadding
                    className="mt-2 mb-20"
                    subheader={
                      d.data.filter(
                        (f) =>
                          f.description?.toLowerCase().includes(searchText?.toLowerCase()) &&
                          f.industries?.some(
                            (_item) => selectedIndustry === 'all' || _item.id === selectedIndustry
                          )
                      )?.length > 0 && (
                        <Typography
                          variant="h3"
                          color="secondary"
                          className="text-left ml-3 text-20 font-600 mb-5"
                        >
                          {d.name}
                        </Typography>
                      )
                    }
                  >
                    {d.data
                      .filter(
                        (f) =>
                          f.description?.toLowerCase().includes(searchText?.toLowerCase()) &&
                          f.industries?.some(
                            (_item) => selectedIndustry === 'all' || _item.id === selectedIndustry
                          )
                      )
                      // eslint-disable-next-line no-shadow
                      .map(({ id, title, description, path, industries, paths }) => (
                        <ListItem
                          alignItems="flex-start"
                          button
                          key={id}
                          onClick={() => {
                            if (paths?.length === 0 || !paths) {
                              dispatch(
                                setSelectedIndustry(
                                  industries?.length > 0 ? industries[0]?._id : selectedIndustry
                                )
                              );
                              dispatch(closeSearchSuggestions());
                              History.push(path.concat(`&s=${title}&iso2=${country}`));
                            }
                          }}
                        >
                          <ListItemIcon className="min-w-5 mr-10">
                            <Search />
                          </ListItemIcon>
                          <ListItemText
                            className="mb-1 text-red-700 text-opacity-90"
                            classes={{
                              primary: 'text-16 font-600 mb-10',
                              secondary: 'text-16 font-600',
                            }}
                            primary={title}
                            secondary={
                              <div>
                                {paths?.map((_path) => {
                                  return (
                                    <Typography
                                      className="block mb-10 hover:underline text-left"
                                      component="button"
                                      onClick={() => {
                                        dispatch(
                                          setSelectedIndustry(
                                            _path.industries?.length > 0
                                              ? _path.industries[0]?._id
                                              : selectedIndustry
                                          )
                                        );
                                        dispatch(closeSearchSuggestions());
                                        History.push(
                                          _path.path.concat(`&s=${title}&iso2=${country}`)
                                        );
                                      }}
                                      color="textSecondary"
                                    >
                                      {_path.description}
                                    </Typography>
                                  );
                                })}
                              </div>
                            }
                          />
                        </ListItem>
                      ))}
                  </List>
                );
              })}
              <List
                dense
                disablePadding
                className="mt-2 mb-20"
                subheader={
                  <Typography
                    variant="h3"
                    color="secondary"
                    className="text-left ml-3 text-20 font-600 mb-5"
                  >
                    Ads & Companies
                  </Typography>
                }
              >
                <ListItem
                  alignItems="flex-start"
                  button
                  onClick={() => {
                    dispatch(setSelectedIndustry(selectedIndustry));
                    dispatch(closeSearchSuggestions());
                    History.push(
                      `/results/industries/${selectedIndustry}?s=${searchText}&iso2=${country}`
                    );
                  }}
                >
                  <ListItemIcon className="min-w-5 mr-10">
                    <Search />
                  </ListItemIcon>
                  <ListItemText
                    className="mb-1 text-red-700 text-opacity-90"
                    classes={{
                      primary: 'text-16 font-600 capitalize',
                      secondary: 'text-16 font-600',
                    }}
                    primary={searchText}
                    secondary={
                      <Typography color="textSecondary">
                        New Search for companies and adverts
                      </Typography>
                    }
                  />
                </ListItem>
                {suggestionsData
                  .filter(
                    (f) =>
                      f.industries?.some(
                        (_item) => selectedIndustry === 'all' || _item.id === selectedIndustry
                      ) && f.title?.toLowerCase().includes(searchText?.toLowerCase())
                  )
                  .map(({ id, title, description }) => (
                    <ListItem
                      alignItems="flex-start"
                      button
                      key={id}
                      onClick={() => {
                        dispatch(setSelectedIndustry(selectedIndustry));
                        dispatch(closeSearchSuggestions());
                        History.push(
                          `/results/industries/${selectedIndustry}?s=${title}&iso2=${country}`
                        );
                      }}
                    >
                      <ListItemIcon className="min-w-5 mr-10">
                        <Search />
                      </ListItemIcon>
                      <ListItemText
                        className="mb-1 text-red-700 text-opacity-90"
                        classes={{
                          primary: 'text-16 font-600',
                          secondary: 'text-16 font-600',
                        }}
                        primary={title}
                        secondary={<Typography color="textSecondary">{description}</Typography>}
                      />
                    </ListItem>
                  ))}
              </List>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default withReducer('suggestions', reducer)(SearchAutocompleteMenu);
