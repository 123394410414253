import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { closeShareAlertDialog } from 'app/auth/store/userSlice';
import { ShareSocial } from 'react-share-social';

const style = {
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
  },
  copyContainer: {
    border: '1px solid blue',
    background: 'rgb(0,0,0,0.7)',
  },
  title: {
    color: 'aquamarine',
    fontStyle: 'italic',
  },
};

export default function ShareAlert() {
  const [open, setOpen] = React.useState(false);
  const dialog = useSelector(({ auth }) => auth.user.shareAlertDialog);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    dispatch(closeShareAlertDialog());
  }

  return (
    <Dialog
      {...dialog?.props}
      onClose={closeComposeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: "m-24 rounded-8",
      }}
    >
      <ShareSocial
        url={window.location.protocol
          .concat("//")
          .concat(window.location.host)
          .concat(dialog.props.data)}
        socialTypes={[
          "facebook",
          "whatsapp",
          "twitter",
          "linkedin",
          "telegram",
          "reddit",
          "line",
          "instapaper",
          "hatena",
          "email",
          "ok",
          "mailru",
        ]}
        // style={style}
      />
    </Dialog>
  );
}
